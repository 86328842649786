import { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { SbButton } from "../atoms/SbButton";

export enum ModalSize {
    Default,
    Medium,
    Large,
}

const StyledModalHeader = styled(Modal.Header)`
    color: ${(props) => props.theme.palette.primary};
    padding-left: ${(props) => props.theme.padding.xl};
    padding-top: ${(props) => props.theme.padding.xl};
    padding-right: ${(props) => props.theme.padding.xl};
`;

const StyledModalBody = styled(Modal.Body)`
    padding-left: ${(props) => props.theme.padding.xl};
    padding-top: ${(props) => props.theme.padding.xl};
    padding-right: ${(props) => props.theme.padding.xl};
`;

const StyledSpan = styled.span`
    color: ${(props) => props.theme.palette.primary};
`;

const StyledHr = styled.hr`
    color: ${(props) => props.theme.palette.secondary};
`;

const SbModal: React.FC<{
    title: string | ReactElement;
    subtitle?: string;
    body: string | ReactElement;
    size?: ModalSize;
    primaryButtonLabel?: string;
    secondaryButtonLabel?: string;
    onPrimaryButtonClicked?: () => void;
    onSecondaryButtonClicked?: () => void;
    isVisible: boolean;
    showCloseButton?: boolean;
    updateIsVisible: (isVisible: boolean) => void;
    centered?: boolean;
}> = ({
    title,
    subtitle,
    body,
    size,
    primaryButtonLabel,
    secondaryButtonLabel,
    onPrimaryButtonClicked,
    onSecondaryButtonClicked,
    isVisible,
    updateIsVisible,
    showCloseButton,
    centered,
}) => {
    const handleClose = (): void => updateIsVisible(false);

    const onPrimaryClicked = (): void => {
        if (primaryButtonLabel && onPrimaryButtonClicked) {
            onPrimaryButtonClicked();
        }

        handleClose();
    };

    const onSecondaryClicked = (): void => {
        if (secondaryButtonLabel && onSecondaryButtonClicked) {
            onSecondaryButtonClicked();
        }
        handleClose();
    };

    const getModalSize = (): "sm" | "lg" | "xl" => {
        switch (size) {
            case ModalSize.Medium:
                return "lg";

            case ModalSize.Large:
                return "xl";

            default:
                return "sm";
        }
    };

    return (
        <>
            <Modal size={getModalSize()} show={isVisible} onHide={handleClose} centered={centered}>
                <StyledModalHeader closeButton={showCloseButton}>
                    <Modal.Title>{title}</Modal.Title>
                </StyledModalHeader>
                <StyledModalBody>
                    {subtitle && (
                        <>
                            <StyledSpan>{subtitle}</StyledSpan>
                            <StyledHr />
                        </>
                    )}
                    {body}
                </StyledModalBody>
                {(secondaryButtonLabel || primaryButtonLabel) && (
                    <Modal.Footer>
                        {secondaryButtonLabel && (
                            <SbButton
                                variant={"secondary"}
                                type={"button"}
                                label={secondaryButtonLabel ?? "Cancel"}
                                onClick={onSecondaryClicked}
                            />
                        )}
                        {primaryButtonLabel && (
                            <SbButton
                                variant={"primary"}
                                type={"button"}
                                label={primaryButtonLabel}
                                onClick={onPrimaryClicked}
                            />
                        )}
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
};

export default SbModal;
