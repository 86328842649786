import LocalStorageHelper from "./local-storage-helper";

const defaultNumericFormat = "2-digit";

export const formatUtcDateInTimeZone = (
    dateUtc: Date,
    locale: string | undefined = LocalStorageHelper.getLanguageCulture(),
    ianaTimeZoneId: string | undefined = LocalStorageHelper.getIanaTimeZoneId()
): string =>
    dateUtc.toLocaleDateString(locale, {
        year: defaultNumericFormat,
        month: defaultNumericFormat,
        day: defaultNumericFormat,
        hour: defaultNumericFormat,
        minute: defaultNumericFormat,
        second: defaultNumericFormat,
        timeZone: ianaTimeZoneId,
    });

export const transformApiUtcDateToUtcDate = (apiDateUtc: Date): Date =>
    apiDateUtc.toString().endsWith("Z")
        ? new Date(apiDateUtc.toString())
        : new Date(`${apiDateUtc.toString()}Z`); // Forcing ISO format

export const formatApiUtcDateInTimeZone = (
    apiDateUtc: Date | null | undefined,
    locale: string | undefined = LocalStorageHelper.getLanguageCulture(),
    ianaTimeZoneId: string | undefined = LocalStorageHelper.getIanaTimeZoneId()
): string =>
    apiDateUtc !== null && apiDateUtc !== undefined
        ? formatUtcDateInTimeZone(transformApiUtcDateToUtcDate(apiDateUtc), locale, ianaTimeZoneId)
        : "";

export const formatApiUtcDate = (
    apiDateUtc: Date,
    locale: string | undefined = LocalStorageHelper.getLanguageCulture()
): string => formatApiUtcDateInTimeZone(apiDateUtc, locale, "UTC");

export const formatUtcDate = (dateUtc: Date, locale: string): string =>
    formatUtcDateInTimeZone(dateUtc, locale, "UTC");

export const transformLocalDateToUtcDate = (dateLocal: Date): Date =>
    new Date(dateLocal.toISOString());

/// This takes in a UTC date string and returns a Local Date.
export const toDateFormat = (dateUtc: string): string => {
    if (!isValidDate(dateUtc)) {
        return "-";
    }

    // Forcing the date to be treated as UTC
    if (!dateUtc.endsWith("Z")) {
        dateUtc = `${dateUtc}Z`;
    }

    // This date is UTC
    const date = new Date(dateUtc);

    // Returns date formatted according to user's locale
    return new Intl.DateTimeFormat(LocalStorageHelper.getLanguageCulture(), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    }).format(date);
};

/// This takes in a UTC date string and returns a Local Time.
export const toTimeFormat = (dateUtc: string): string => {
    if (!isValidDate(dateUtc)) {
        return "-";
    }

    // Forcing the date to be treated as UTC
    if (!dateUtc.endsWith("Z")) {
        dateUtc = `${dateUtc}Z`;
    }

    // This date is UTC
    const date = new Date(dateUtc);

    // Returns time formatted according to user's locale
    return new Intl.DateTimeFormat(LocalStorageHelper.getLanguageCulture(), {
        hour: "numeric",
        minute: "numeric",
    }).format(date);
};

/// This takes in a UTC date string and returns a Local Date and Time
export const toDateAndTimeFormat = (dateUtc: string): string => {
    if (!isValidDate(dateUtc)) {
        return "-";
    }

    // Forcing the date to be treated as UTC
    if (!dateUtc.endsWith("Z")) {
        dateUtc = `${dateUtc}Z`;
    }

    // This date is UTC
    const date = new Date(dateUtc);

    // Returns date and time formatted according to user's locale
    return new Intl.DateTimeFormat(LocalStorageHelper.getLanguageCulture(), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
    }).format(date);
};

const isValidDate = (date: string): boolean => !isNaN(Date.parse(date));

export const convertUtcDateToLocalDate = (
    date: Date,
    locale: string | undefined = LocalStorageHelper.getLanguageCulture(),
    ianaTimeZoneId: string | undefined = LocalStorageHelper.getIanaTimeZoneId()
): Date =>
    new Date(
        `${date.toLocaleString(locale, {
            timeZone: ianaTimeZoneId,
        })}Z`
    );

/// This takes in a local date string and returns a formatted Local Date and Time
export const toDateAndTimeFormatLocal = (dateLocal: string): string => {
    if (!isValidDate(dateLocal)) {
        return "-";
    }

    // This date is local
    const date = new Date(dateLocal);

    // Returns date and time formatted according to user's locale
    return new Intl.DateTimeFormat(LocalStorageHelper.getLanguageCulture(), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
    }).format(date);
};
