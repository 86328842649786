import {
    buildFilterByNodeSearchParameters,
    FilterByNodeRequest,
} from "../../../domain/requests/common/filter-by-node-request";
import { CreateUserGroupRequest } from "../../../domain/requests/users/create-user-group-request";
import {
    buildFilterSearchParameters,
    FilterUserGroupsRequest,
} from "../../../domain/requests/users/filter-user-groups-request";
import { UserGroupRequest } from "../../../domain/requests/users/user-group-details-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { UserGroupResponse } from "../../../domain/responses/users/user-group-response";
import client from "../../http-client";

const route = "usergroups";

export const filterUserGroups = async (
    prefixUrl: string,
    request: FilterUserGroupsRequest
): Promise<Response<PaginationResponse<UserGroupResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getUserGroupDetails = async (
    prefixUrl: string,
    userGroupId: number
): Promise<Response<UserGroupResponse>> =>
    await client
        .get(`${route}/${userGroupId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterUserGroupsByNode = async (
    prefixUrl: string,
    request: FilterByNodeRequest
): Promise<Response<PaginationResponse<UserGroupResponse>>> => {
    const searchParams = buildFilterByNodeSearchParameters(request);

    return await client
        .get(`${route}/user-group-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const updateUserGroupDetails = async (
    prefixUrl: string,
    request: UserGroupRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const softDeleteUserGroup = async (
    prefixUrl: string,
    userGroupId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/delete/${userGroupId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createUserGroup = async (
    prefixUrl: string,
    request: CreateUserGroupRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();
