import { CreateUserGroupDto } from "../../dtos/user-groups/user-group-dto";

export class CreateUserGroupRequest {
    name: string;
    emailAddress: string;
    description: string;

    public constructor(dto: CreateUserGroupDto)
    {
        this.name = dto.name;
        this.emailAddress = dto.emailAddress;
        this.description = dto.description;
    }
}