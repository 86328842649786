import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { getDateFormFieldInUtc } from "../../../core/utilities/formDataHelper";
import {
    filterAdhocQuestionSetSchedules,
    filterARMonitoringReferentialLinkTypes,
    saveNodeChecklistDefaultValues,
    triggerAdhocQuestionSet,
} from "../../../data/services/checklists/adhoc-checklists-service";
import { getNodeAdhocIntervalOverride } from "../../../data/services/checklists/node-adhoc-interval-overrides-service";
import { getNodeReferentialLinks } from "../../../data/services/checklists/node-referential-links-service";
import { filterFunctionUserNodes } from "../../../data/services/hierarchy/nodes-service";
import { lookupMaxReferentialLinkValuesCharacters } from "../../../data/services/lookup/lookup-service";
import { filterReferentialLinks } from "../../../data/services/referential-link-types/referential-link-service";
import { filterReviewReportSetIdByReportName } from "../../../data/services/review-reports/review-reports-service";
import { filterUsersByNode } from "../../../data/services/users/users-service";
import AdhocChecklistNotificationDto from "../../dtos/checklists/adhoc-checklist-notification-dto";
import AdhocQuestionSetAssignmentScheduleDto from "../../dtos/checklists/adhoc-question-set-assignment-schedule-dto";
import IntervalOverridesDto from "../../dtos/checklists/interval-overrides-dto";
import NodeChecklistDefaultValuesDto from "../../dtos/checklists/node-checklist-default-values-dto";
import TriggerAdhocQuestionSetDto from "../../dtos/checklists/trigger-adhoc-question-set-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import PaginatedDto, { createPaginatedDtoFromResponse } from "../../dtos/common/paginated-dto";
import FunctionUserNodesDto from "../../dtos/hierarchy/function-user-nodes-dto";
import ARMonitoringReferentialLinkWithTypeDto from "../../dtos/referential-links/ar-monitoring-referential-link-with-type-dto";
import BaseReferentialLinkDto from "../../dtos/referential-links/base-referential-link-dto";
import { FilterReferentialLinkTypeDto } from "../../dtos/referential-links/filter-referential-link-type-dto";
import NodeReferentialLinksDto from "../../dtos/referential-links/node-referential-links-dto";
import {
    BaseUserDto,
    toBaseUserDtosFromPaginatedUserResponse,
} from "../../dtos/users/base-user-dto";
import { PeriodType } from "../../enums/PeriodType";
import FilterByNodeWithARMonitoringRequest from "../../requests/checklists/filter-by-node-with-ar-monitoring-request";
import NodeChecklistDefaultValuesRequest from "../../requests/checklists/node-checklist-default-values-request";
import TriggerAdhocQuestionSetRequest from "../../requests/checklists/trigger-adhoc-question-set-request";
import { createBasePaginationRequestFromDto } from "../../requests/common/base-pagination-request";
import { createFilterByNodeRequest } from "../../requests/common/filter-by-node-request";
import { createFilterReferentialLinkTypeRequestFromDto } from "../../requests/referential-link-types/filter-referential-link-type-request";
import { AdhocQuestionSetAssignmentScheduleResponse } from "../../responses/checklists/adhoc-question-set-assignment-schedule-response";
import { IntervalOverridesResponse } from "../../responses/checklists/interval-overrides-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { ReferentialLinkResponse } from "../../responses/referential-links/referential-link-response";
import { ReferentialLinkTypeResponse } from "../../responses/referential-links/referential-link-type-response";

export const useFilterFunctionUserNodes = (): UseQueryResult<FunctionUserNodesDto, HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterFunctionUserNodes", auth.userId],
        () => filterFunctionUserNodes(url.baseUrl, auth.userId!),
        {
            enabled: auth.userId != null,
            select: FunctionUserNodesDto.createFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterAdhocQuestionSetSchedules = (
    paginationDto: BasePaginationDto,
    nodeId: number | null,
    isARMonitoring: boolean
): UseQueryResult<PaginatedDto<AdhocQuestionSetAssignmentScheduleDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAdhocQuestionSetSchedules", nodeId, isARMonitoring, paginationDto],
        () =>
            filterAdhocQuestionSetSchedules(
                url.baseUrl,
                FilterByNodeWithARMonitoringRequest.createFilterByNodeWithARMonitoringRequest(
                    nodeId!,
                    isARMonitoring,
                    createBasePaginationRequestFromDto(paginationDto)
                )
            ),
        {
            enabled: nodeId != null,
            select: toPaginatedAdhocQuestionSetAssignmentScheduleDtoDtos,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterUsersByNode = (
    nodeId: number | null,
    teamLeadView: boolean
): UseQueryResult<BaseUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsersByNode", nodeId],
        () => filterUsersByNode(url.baseUrl, createFilterByNodeRequest(nodeId!, false, 1, 1000)),
        {
            enabled: nodeId != null && teamLeadView,
            select: toBaseUserDtosFromPaginatedUserResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterARMonitoringReferentialLinkTypes = (
    nodeId: number | null
): UseQueryResult<ARMonitoringReferentialLinkWithTypeDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterARMonitoringReferentialLinkTypes"],
        () => filterARMonitoringReferentialLinkTypes(url.baseUrl),
        {
            enabled: nodeId != null,
            select: (response: Response<ReferentialLinkTypeResponse[]>) =>
                ARMonitoringReferentialLinkWithTypeDto.toARMonitoringReferentialLinkWithTypeDto(
                    response.data
                ),
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetNodeChecklistDefaultValuesData = (
    nodeId: number | null,
    arMonitoring: boolean
): [
    UseQueryResult<NodeReferentialLinksDto[] | undefined, HTTPError>,
    UseQueryResult<IntervalOverridesDto | undefined, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getNodeReferentialLinks", nodeId],
                queryFn: () => getNodeReferentialLinks(url.baseUrl, nodeId!),
                enabled: nodeId != null && arMonitoring,
                select: (response: Response<ReferentialLinkResponse[]>) =>
                    response.data.length > 0
                        ? NodeReferentialLinksDto.toNodeReferentialLinksDto(response.data)
                        : undefined,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getNodeAdhocIntervalOverride", nodeId],
                queryFn: () => getNodeAdhocIntervalOverride(url.baseUrl, nodeId!),
                enabled: nodeId != null && arMonitoring,
                select: (response: Response<IntervalOverridesResponse>) =>
                    response.data.adhocIntervalResponse !== null
                        ? IntervalOverridesDto.toIntervalOverridesDto(response.data)
                        : undefined,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<NodeReferentialLinksDto[] | undefined, HTTPError>,
        UseQueryResult<IntervalOverridesDto | undefined, HTTPError>,
    ];
};

export const useTriggerAdhocQuestionSet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    TriggerAdhocQuestionSetDto
> => {
    const url = useUrl();

    return useMutation((dto: TriggerAdhocQuestionSetDto) => {
        const request = new TriggerAdhocQuestionSetRequest(dto);

        return triggerAdhocQuestionSet(url.baseUrl, request);
    });
};

export const useLookupMaxReferentialLinkValuesCharacters = (): UseQueryResult<
    number,
    HTTPError
> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["lookupMaxReferentialLinkValuesCharacters"],
        () => lookupMaxReferentialLinkValuesCharacters(url.baseUrl),
        {
            select: (response) => response.data,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useSaveNodeChecklistDefaultValues = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    NodeChecklistDefaultValuesDto
> => {
    const url = useUrl();

    return useMutation((dto: NodeChecklistDefaultValuesDto) => {
        const request = new NodeChecklistDefaultValuesRequest(dto);

        return saveNodeChecklistDefaultValues(url.baseUrl, request);
    });
};

export const buildIntervalOverridesDto = (
    formData: FormData,
    openBeforeDuePeriodType: PeriodType | null,
    allowAdhocIntervalOverride: boolean
): IntervalOverridesDto | null => {
    if (!allowAdhocIntervalOverride) {
        return null;
    }

    const dueDateUtc = getDateFormFieldInUtc(formData.get("dueDate") as string);
    const openBeforeDueValue = Number(formData.get("openBeforeDueValue"));

    return new IntervalOverridesDto(dueDateUtc, openBeforeDueValue, openBeforeDuePeriodType!);
};

export const buildAdhocChecklistNotificationDto = (
    formData: FormData,
    allowCustomNotifications: boolean
): AdhocChecklistNotificationDto | null => {
    if (!allowCustomNotifications) {
        return null;
    }

    const daysNotificationOne = Number(formData.get("daysNotificationOne"));
    const daysNotificationTwo = Number(formData.get("daysNotificationTwo"));
    const hoursNotification = Number(formData.get("hoursNotification"));

    return new AdhocChecklistNotificationDto(
        daysNotificationOne,
        daysNotificationTwo,
        hoursNotification
    );
};

const toPaginatedAdhocQuestionSetAssignmentScheduleDtoDtos = (
    response: Response<PaginationResponse<AdhocQuestionSetAssignmentScheduleResponse>>
): PaginatedDto<AdhocQuestionSetAssignmentScheduleDto> => {
    const responseData = response.data;

    return createPaginatedDtoFromResponse(
        responseData,
        AdhocQuestionSetAssignmentScheduleDto.constructFromResponses(responseData.results)
    );
};

export const useFilterReviewReportSetIdByReportName = (): UseMutationResult<
    Response<number>,
    HTTPError,
    string
> => {
    const url = useUrl();

    return useMutation((reportName: string) =>
        filterReviewReportSetIdByReportName(url.baseUrl, reportName)
    );
};

export const useFilterReferentialLinks = (
    filterDto: FilterReferentialLinkTypeDto
): UseQueryResult<BaseReferentialLinkDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterReferentialLinks", filterDto],
        () =>
            filterReferentialLinks(
                url.baseUrl,
                // FilterReferentialLinkTypeRequest is used when filtering both referential links and referntial link types
                // since both endpoints require the same request values
                createFilterReferentialLinkTypeRequestFromDto(filterDto)
            ),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ReferentialLinkResponse>>
): BaseReferentialLinkDto[] => {
    const responseData = response.data;

    const rows = responseData.results!.map((referentialLink) => {
        return new BaseReferentialLinkDto(referentialLink.referentialLinkId, referentialLink.value);
    });

    return rows;
};
