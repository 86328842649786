import {
    buildFilterByNodeSearchParameters,
    FilterByNodeRequest,
} from "../../../domain/requests/common/filter-by-node-request";
import { EditProfileRequest } from "../../../domain/requests/profile/edit-profile-request";
import { CreateAdminUserRequest } from "../../../domain/requests/users/create-admin-user-request";
import { CreateTenantUserRequest } from "../../../domain/requests/users/create-tenant-user-request";
import FilterAssociatedUserGroupUsersRequest from "../../../domain/requests/users/filter-associated-user-group-users-request";
import {
    buildFilterSearchUserAssignedActionItemsParameters,
    FilterUserAssignedActionItemsRequest,
} from "../../../domain/requests/users/filter-user-actions-requests";
import {
    buildFilterSearchParameters,
    FilterUsersRequest,
} from "../../../domain/requests/users/filter-users-request";
import { UserDetailsRequest } from "../../../domain/requests/users/user-details-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { BaseUserResponse } from "../../../domain/responses/users/base-user-response";
import { FilterUserAssignedActionItemsResponse } from "../../../domain/responses/users/filter-user-actions-response";
import { UserClaimResponse } from "../../../domain/responses/users/user-claim-response";
import { UserDetailsResponse } from "../../../domain/responses/users/user-details-response";
import { UserResponse } from "../../../domain/responses/users/user-response";
import client from "../../http-client";

const route = "users";

export const createAdminUser = async (
    prefixUrl: string,
    request: CreateAdminUserRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/create-admin`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const createTenantUser = async (
    prefixUrl: string,
    request: CreateTenantUserRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/create-tenant`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const filterUsers = async (
    prefixUrl: string,
    request: FilterUsersRequest
): Promise<Response<PaginationResponse<UserResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getUserDetails = async (
    prefixUrl: string,
    userId: number
): Promise<Response<UserDetailsResponse>> =>
    await client
        .get(`${route}/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const updateUserDetails = async (
    prefixUrl: string,
    request: UserDetailsRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const softDeleteUser = async (
    prefixUrl: string,
    userId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/delete/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const unDeleteUser = async (prefixUrl: string, userId: number): Promise<Response<boolean>> =>
    await client
        .put(`${route}/undelete/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const lockoutUser = async (prefixUrl: string, userId: number): Promise<Response<boolean>> =>
    await client
        .put(`${route}/lockout/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const resetUserLockout = async (
    prefixUrl: string,
    userId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/reset-lockout/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterUsersByNode = async (
    prefixUrl: string,
    request: FilterByNodeRequest
): Promise<Response<PaginationResponse<BaseUserResponse>>> => {
    const searchParams = buildFilterByNodeSearchParameters(request);

    return await client
        .get(`${route}/user-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterUsersBelowNode = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<BaseUserResponse[]>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("nodeId", nodeId.toString());

    return await client
        .get(`${route}/filter-below-node`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getUserClaim = async (
    prefixUrl: string,
    userId: number
): Promise<Response<UserClaimResponse>> =>
    await client
        .get(`${route}/user-claim/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editProfile = async (
    prefixUrl: string,
    request: EditProfileRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit-profile`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const filterUserAssignedActionItems = async (
    prefixUrl: string,
    request: FilterUserAssignedActionItemsRequest,
    userId: number
): Promise<Response<PaginationResponse<FilterUserAssignedActionItemsResponse>>> => {
    const searchParams = buildFilterSearchUserAssignedActionItemsParameters(request);
    return await client
        .get(`${route}/filter-user-assigned-action-items/${userId}`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterAssociatedUserGroupUsers = async (
    prefixUrl: string,
    request: FilterAssociatedUserGroupUsersRequest
): Promise<Response<PaginationResponse<BaseUserResponse>>> => {
    const searchParams = request.buildSearchParameters();

    return await client
        .get(`${route}/user-groups/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
