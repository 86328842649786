import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { UserGroupResponse } from "../../responses/users/user-group-response";

export interface UserGroupDto {
    userGroupId: number;
    name: string;
    description: string;
    email: string;
    userCount: number;
    nodeCount: number;
    createdByEmailAddress: string;
    dateCreatedUtc: Date;
    modifiedByEmailAddress: string;
    dateModifiedUtc: Date;
    isDeleted: boolean;
}

export const toUserGroupDto = (userGroup: UserGroupResponse): UserGroupDto => ({
    userGroupId: userGroup.userGroupId,
    name: userGroup.name,
    description: userGroup.description,
    email: userGroup.email,
    userCount: userGroup.userCount,
    nodeCount: userGroup.nodeCount,
    createdByEmailAddress: userGroup.createdByEmailAddress,
    dateCreatedUtc: userGroup.dateCreatedUtc,
    modifiedByEmailAddress: userGroup.modifiedByEmailAddress,
    dateModifiedUtc: userGroup.dateModifiedUtc,
    isDeleted: userGroup.isDeleted,
});

export const createUserGroupDtoFromUserGroupResponse = (
    response: UserGroupResponse
): UserGroupDto => toUserGroupDto(response);

export const toUserGroupDtosFromPaginatedUserGroupResponse = (
    response: Response<PaginationResponse<UserGroupResponse>>
): UserGroupDto[] => response.data.results.map((x) => createUserGroupDtoFromUserGroupResponse(x));
