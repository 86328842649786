import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    getActionItemSubTypes,
    getActionItemTypes,
} from "../../../data/services/action-items/action-item-types-service";
import { getActionInstanceStatus } from "../../../data/services/actions/action-instance-status-service";
import { getActionItemImportance } from "../../../data/services/actions/action-item-importance-service";
import { filterActionItems } from "../../../data/services/actions/action-items-service";
import { associateActionItem } from "../../../data/services/actions/action-plans-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import {
    ActionInstanceStatusDto,
    toActionInstanceStatusDtos,
} from "../../dtos/actions/action-instance-status-dto";
import {
    ActionItemImportanceDto,
    toActionItemImportanceDtos,
} from "../../dtos/actions/action-item-importance-dto";
import { FilterActionItemDto } from "../../dtos/actions/filter-action-items-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { ActionInstanceStatus } from "../../enums/action-items/ActionInstanceStatus";
import { createAssociateActionItemToActionPlanRequest } from "../../requests/actions/associate-action-item-to-action-plan-request";
import { createFilterActionItemsRequest } from "../../requests/actions/filter-action-items-request";
import { ActionItemResponse } from "../../responses/actions/action-item-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { ActionItems } from "../../../core/constants/translation-namespace";
import { useTranslation } from "react-i18next";
import { translateText } from "../../../presentation/helpers/translate";

export const useFilterAssociateActionItems = (
    filterActionItemDto: FilterActionItemDto,
    paginationDto: BasePaginationDto
): [
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemImportanceDto[], HTTPError>,
    UseQueryResult<ActionInstanceStatusDto[], HTTPError>,
] => {
    const url = useUrl();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["filterActionItems", filterActionItemDto, paginationDto],
                queryFn: () =>
                    filterActionItems(
                        url.baseUrl,
                        createFilterActionItemsRequest(filterActionItemDto, paginationDto)
                    ),
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<ActionItemResponse>>) =>
                    transformToDataTableRows(response, t),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemSubTypes"],
                queryFn: () => getActionItemSubTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemImportance"],
                queryFn: () => getActionItemImportance(url.baseUrl),
                select: toActionItemImportanceDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionInstanceStatus"],
                queryFn: () => getActionInstanceStatus(url.baseUrl),
                select: toActionInstanceStatusDtos,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemImportanceDto[], HTTPError>,
        UseQueryResult<ActionInstanceStatusDto[], HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ActionItemResponse>>,
    t: any
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((actionItem) => ({
        metadata: actionItem.actionItemId,
        columns: [
            {
                value: actionItem.actionItemId?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: actionItem.name?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: actionItem.actionItemTypeName?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: actionItem.actionItemSubTypeNodeName?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: translateText(t, ActionInstanceStatus[actionItem.actionInstanceStatus].toString(), ActionItems),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(actionItem.dueDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

interface AssociateParameters {
    actionPlanId: number;
    actionItemIds: number[];
}

export const createAssociateParameters = (
    actionPlanId: number,
    actionItemIds: number[]
): AssociateParameters => ({
    actionPlanId: actionPlanId,
    actionItemIds: actionItemIds,
});

export const useAssociateActionItem = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateParameters) => {
        const request = createAssociateActionItemToActionPlanRequest(
            mutationKey.actionPlanId,
            mutationKey.actionItemIds
        );

        return associateActionItem(url.baseUrl, request);
    });
};
