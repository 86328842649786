import { FilterUserGroupsDto } from "../../dtos/users/filter-user-groups-dto";
import {
    buildPaginationSearchParameters,
    createPaginationRequest,
    PaginationRequest,
} from "../common/pagination-request";

export interface FilterUserGroupsRequest extends FilterUserGroupsDto, PaginationRequest {}

export const createFilterUserGroupsRequestFromDto = (
    dto: FilterUserGroupsDto
): FilterUserGroupsRequest => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = dto;

    return {
        ...dto,
        ...createPaginationRequest(pageNumber, pageSize, sortByColumn, sortByDescending),
    };
};

export const createFilterUserGroupsSearchRequest = (
    userId: number | null,
    searchText: string | null,
    pageNumber: number,
    pageSize: number
): FilterUserGroupsRequest => ({
    userId: userId,
    name: searchText,
    email: searchText,
    ...createPaginationRequest(pageNumber, pageSize, null, false),
});

export const buildFilterSearchParameters = (request: FilterUserGroupsRequest): URLSearchParams => {
    const searchParams = buildPaginationSearchParameters(request);
    if (request.name) {
        searchParams.set("name", request.name);
    }

    if (request.email) {
        searchParams.set("email", request.email);
    }

    if (request.userId) {
        searchParams.set("userId", request.userId.toString());
    }

    return searchParams;
};
