import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { AssociateDissociateUserGroupNodeDto } from "../../../domain/dtos/user-groups/associate-dissociate-user-group-node-dto";
import { useDissociateUserGroupNode } from "../../../domain/viewmodels/user-groups/dissociate-user-group-node-viewmodel";
import { useGetUserGroupDetails } from "../../../domain/viewmodels/user-groups/view-user-group-details-viewmodel";
import { DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateUserGroupNodeContainer: React.FC = () => {
    const userGroupId = Number(useParams().userGroupId);

    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const nodeId = Number(urlSearchParams.get("nodeId"));

    const getUserGroupDetails = useGetUserGroupDetails(userGroupId);
    const getUserGroupDetailsResponseData = getUserGroupDetails.data;

    const dissociateUserGroupNode = useDissociateUserGroupNode();

    useLoader(
        isQueryLoading(getUserGroupDetails) || isMutationLoading(dissociateUserGroupNode),
        DissociateUserGroupNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.UserGroups);
    }, []);

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={handleDissociateNode} />
    );

    const handleDissociateNode = (): void => {
        dissociateUserGroupNode.mutate(
            new AssociateDissociateUserGroupNodeDto(nodeId!, userGroupId!),
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries(["getAssociatedUserGroupNodesFilter"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyDissociatedUserGroupFromNode"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.UserGroups)}/${userGroupId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("UserGroupName"), getUserGroupDetailsResponseData?.name),
    ];

    return (
        isQuerySuccessful(getUserGroupDetails) && (
            <ActionConfirmationFragment
                pageHeading={t("DissociateUserGroupNode")}
                panelTitle={t("DissociateNodeUserGroupConfirmationDisplay")}
                rows={buildConfirmationRows()}
                primaryButton={buildDissociateButton()}
            />
        )
    );
};

export default DissociateUserGroupNodeContainer;
