import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { TextSpan } from "typescript";
import { Common, Nodes, Users } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    associatedUserGroupNodeColumnNames,
    associatedUserGroupUserColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { areQueriesLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { BasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { hasRoleTypeInGroup, UserRoleGroup } from "../../../domain/enums/Roles";
import {
    useFilterAssociatedUserGroupNodes,
    useFilterAssociatedUserGroupUsers,
    useGetUserGroupDetails,
} from "../../../domain/viewmodels/user-groups/view-user-group-details-viewmodel";
import { CreateLink, EditLink, ViewLink } from "../../atoms/SbLink";
import { CreateLabelToDetailRows } from "../../molecules/CreateLabelToDetailRows";
import { SbAccordion } from "../../molecules/SbAccordion";
import { ConfirmationRow, TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { DataTable } from "../../organisms/DataTable";

export interface ConfirmationDetailsProps {
    username: string;
}

const UserGroupDetailsContainer: React.FC = () => {
    const menu = useMenu();
    const toast = useToast();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();

    const { t } = useTranslation("translation", { keyPrefix: Users });

    const [associatedNodesPaginationDto, setAssociatedNodesPaginationDto] =
        useState<BasePaginationDto>(defaultPaginationDto);

    const [assignedUsersPaginationDto, setAssignedUsersPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const userGroupId = Number(useParams().userGroupId);

    const getUserGroupDetails = useGetUserGroupDetails(userGroupId);

    const filterAssociatedUserGroupNodes = useFilterAssociatedUserGroupNodes(
        userGroupId,
        associatedNodesPaginationDto
    );

    const filterAssignedUserGroupUsers = useFilterAssociatedUserGroupUsers(
        userGroupId,
        assignedUsersPaginationDto
    );

    const getUserGroupDetailsResponseData = getUserGroupDetails.data;
    const filterUserGroupNodesResponseData = filterAssociatedUserGroupNodes.data;

    useLoader(
        areQueriesLoading([getUserGroupDetails, filterAssociatedUserGroupNodes]),
        UserGroupDetailsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.UserGroups);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const buildEditAndViewUserGroupsLinks = (): ReactElement<typeof Link> => (
        <>
            {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.WriteRoles) && (
                <EditLink
                    navigateTo={`${getPath(AccordionTitles.UserGroups)}/${userGroupId}/edit`}
                />
            )}
            <ViewLink label={t("UserGroups")} navigateTo={getPath(AccordionTitles.UserGroups)} />
        </>
    );

    const navigateToDissociateNode = (nodeId: number): void => {
        const params = [createNavigateSearchParameter("nodeId", nodeId.toString())];

        navigateSearch(
            `${getPath(AccordionTitles.UserGroups)}/${userGroupId}/dissociate-node`,
            params
        );
    };

    const renderAssociatedNodes = (): JSX.Element => (
        <>
            <SectionVerticalSpace />
            <SbAccordion title={t("AssignedNodes", { keyPrefix: Nodes })}>
                {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.AssignRoles) && (
                    <EndAlignedDiv>
                        <CreateLink
                            label={t("AssignToNode", { keyPrefix: Nodes })}
                            navigateTo={`${getPath(
                                AccordionTitles.UserGroups
                            )}/${userGroupId}/associate-node`}
                        />
                    </EndAlignedDiv>
                )}
                <DataTable
                    noResultsMessage={t("NoNodesFound", { keyPrefix: Nodes })}
                    columns={associatedUserGroupNodeColumnNames}
                    rows={filterUserGroupNodesResponseData!.rows}
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.AssignRoles)
                            ? navigateToDissociateNode
                            : undefined
                    }
                    totalItems={filterUserGroupNodesResponseData!.recordCount}
                    paginationDto={associatedNodesPaginationDto}
                    setPaginationDto={setAssociatedNodesPaginationDto}
                />
            </SbAccordion>
        </>
    );

    const buildAssignedUsers = (): ReactElement<HTMLTableElement | TextSpan> => (
        <DataTable
            noResultsMessage={t("NoAssignedUsers", { keyPrefix: Users })}
            columns={associatedUserGroupUserColumnNames}
            rows={filterAssignedUserGroupUsers.data?.rows}
            totalItems={filterAssignedUserGroupUsers.data?.recordCount}
            paginationDto={assignedUsersPaginationDto}
            setPaginationDto={setAssignedUsersPaginationDto}
        />
    );

    const renderAssociatedUsers = (): JSX.Element => (
        <>
            <SectionVerticalSpace />
            <SbAccordion title={t("Users", { keyPrefix: Users })}>
                {buildAssignedUsers()}
            </SbAccordion>
        </>
    );

    const confirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(t("GroupName"), getUserGroupDetailsResponseData!.name),
        new TextConfirmationRow(t("Description"), getUserGroupDetailsResponseData!.description),
        new TextConfirmationRow(t("GroupEmailAddress"), getUserGroupDetailsResponseData!.email),
        new TextConfirmationRow(
            t("NumberofUsers"),
            getUserGroupDetailsResponseData!.userCount?.toString()
        ),
        new TextConfirmationRow(
            t("NumberofNodes"),
            getUserGroupDetailsResponseData!.nodeCount?.toString()
        ),
        new TextConfirmationRow(
            t("CreatedByUserEmail"),
            getUserGroupDetailsResponseData!.createdByEmailAddress
        ),
        new TextConfirmationRow(
            t("DateCreated", { keyPrefix: Common }),
            toDateAndTimeFormat(getUserGroupDetailsResponseData!.dateCreatedUtc.toString())
        ),
        new TextConfirmationRow(
            t("ModifiedByUserEmail"),
            getUserGroupDetailsResponseData!.modifiedByEmailAddress
        ),
        new TextConfirmationRow(
            t("DateLastModified", { keyPrefix: Common }),
            toDateAndTimeFormat(getUserGroupDetailsResponseData!.dateModifiedUtc.toString())
        ),
    ];

    return (
        <>
            <PageHeading>{t("UserGroupDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getUserGroupDetails) && (
                <>
                    <ContentContainer>
                        {CreateLabelToDetailRows(confirmationRows(), 2, 10)}

                        <EndAlignedDiv>{buildEditAndViewUserGroupsLinks()}</EndAlignedDiv>
                    </ContentContainer>
                </>
            )}
            {isQuerySuccessful(filterAssignedUserGroupUsers) && renderAssociatedUsers()}
            {isQuerySuccessful(filterAssociatedUserGroupNodes) && renderAssociatedNodes()}
        </>
    );
};

export default UserGroupDetailsContainer;
