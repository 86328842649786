export interface UserNodeAssociationDissociationRequest {
    nodeId: number;
    userId: number;
}

export const createUserNodeAssociationDissociationRequest = (
    nodeId: number,
    userId: number
): UserNodeAssociationDissociationRequest => ({
    nodeId: nodeId,
    userId: userId,
});

export const buildUserNodeAssociationDissociationSearchParameters = (
    request: UserNodeAssociationDissociationRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    searchParams.set("nodeId", request.nodeId.toString());
    searchParams.set("userId", request.userId.toString());

    return searchParams;
};
