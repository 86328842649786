import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterUserGroupsByNode } from "../../../data/services/users/user-groups-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { createFilterByNodeRequestFromPaginationDto } from "../../requests/common/filter-by-node-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { UserGroupResponse } from "../../responses/users/user-group-response";

export const useFilterUserGroupsByNode = (
    nodeId: number,
    assignedUserGroupsPaginationDto: PaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUserGroupsByNode", nodeId, assignedUserGroupsPaginationDto],
        () =>
            filterUserGroupsByNode(
                url.baseUrl,
                createFilterByNodeRequestFromPaginationDto(
                    nodeId,
                    false,
                    assignedUserGroupsPaginationDto
                )
            ),
        {
            select: (response: Response<PaginationResponse<UserGroupResponse>>) =>
                transformToUserGroupDtoDataTableRows(response.data, navigate),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToUserGroupDtoDataTableRows = (
    response: PaginationResponse<UserGroupResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results!.map((x) => ({
        metadata: x.userGroupId,
        columns: [
            {
                value: `${x.name} ${x.email}`,
                type: DataTableColumnTypes.Link,
                linkItemAction: (userGroupId: number) =>
                    navigate(`${getPath(AccordionTitles.UserGroups)}/${userGroupId}`),
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
