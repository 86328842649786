import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { dissociateUserGroupNode } from "../../../data/services/hierarchy/nodes-service";
import { createUserGroupNodeAssociationDissociationRequest } from "../../../domain/requests/hierarchy/user-group-node-association-dissociation-request";
import { AssociateDissociateUserGroupNodeDto } from "../../dtos/user-groups/associate-dissociate-user-group-node-dto";
import { Response } from "../../responses/common/response-response";

export const useDissociateUserGroupNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateUserGroupNodeDto
> => {
    const url = useUrl();

    return useMutation((dto: AssociateDissociateUserGroupNodeDto) =>
        dissociateUserGroupNode(url.baseUrl, createUserGroupNodeAssociationDissociationRequest(dto))
    );
};
