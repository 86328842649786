import { TFunction } from "i18next";
import React, { ReactElement, useContext } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCaretSquareDown, FaCaretSquareRight } from "react-icons/fa";
import styled, { ThemeContext } from "styled-components";
import { Common, Reports } from "../../core/constants/translation-namespace";
import loaderImage from "../../core/images/logo_loading_spinner.gif";
import {
    ContentContainer,
    EndAlignedDiv,
    maxContentWidthSelectStyle,
    NoDataStateDiv,
} from "../../core/theme/global-styles";
import { Column, TableRow } from "../../core/utilities/customTypes";
import { DataTableColumnTypes } from "../../core/utilities/enums";
import { PeriodTypeOptions } from "../../core/utilities/QuestionSetValues";
import { BasePaginationDto } from "../../domain/dtos/common/base-pagination-dto";
import { ReactSelectDto, valuesToReactSelectDtos } from "../../domain/dtos/common/react-select-dto";
import { FileDownloadDto } from "../../domain/dtos/file-storage/file-download-dto";
import { PeriodType } from "../../domain/enums/PeriodType";
import FormattedString from "../atoms/FormattedString";
import SbCheckField from "../atoms/input/SbCheckField";
import SbDateTimeField from "../atoms/input/SbDateTimeField";
import { SbSelect } from "../atoms/input/SbSelect";
import Paginator from "../atoms/Paginator";
import { CancelButton, SaveButton, SbButton } from "../atoms/SbButton";
import { translateText } from "../helpers/translate";
import { DataTableAction, RadioSelectProps } from "../molecules/DataTableAction";
import { FileGallery } from "./FileGallery";
import { NestedRemoveOrResolveForm } from "./NestedRemoveOrResolveCommentForm";

export const StyledTable = styled(Table)`
    background-color: white;
`;

export const StyledGridP = styled.p`
    color: ${(props) => props.theme.palette.primary};
    font-weight: bold;
    text-align: left;
`;

const StyledSortGridP = styled.p`
    color: ${(props) => props.theme.palette.primary};
    font-weight: bold;
    text-align: left;
    cursor: pointer;

    :hover {
        color: ${(props) => props.theme.palette.secondary};
    }
`;

const StyledTd = styled.td`
    white-space: nowrap;
    width: 1%;
`;

const StyledLinkTd = styled.td`
    color: ${(props) => props.theme.palette.primary};
    cursor: pointer;
`;

const StyledLinkAndRibbonTd = styled.td`
    overflow: hidden;
    position: relative;
    color: ${(props) => props.theme.palette.primary};
    cursor: pointer;
`;

const StyledInput = styled.input`
    min-width: ${(props) => props.theme.dimensions.width.xxxl};
    height: ${(props) => props.theme.dimensions.height.xl};
    background-color: ${(props) => props.theme.palette.purewhite};
    background-image: none;
    border: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    border-radius: 0.5rem;
    color: inherit;
    transition: border-color 0.15s;
    font-size: ${(props) => props.theme.font.md};
    text-align: center;
`;

const StyledDisabledInput = styled.input`
    width: ${(props) => props.theme.dimensions.width.xxxxl};
    height: ${(props) => props.theme.dimensions.height.xl};
    background-color: ${(props) => props.theme.palette.grey};
    background-image: none;
    border: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    border-radius: 0.5rem;
    color: inherit;
    transition: border-color 0.15s;
    font-size: ${(props) => props.theme.font.md};
`;

const StyledNestedTd = styled.th`
    font-weight: normal;
`;

const StyledNestedDiv = styled.div`
    border-radius: 0.5rem;
    border: ${(props) => props.theme.padding.sm} solid ${(props) => props.theme.palette.blue};
`;

const StyledAttachedFilesDiv = styled.div`
    border-radius: 0.5rem;
    border: ${(props) => props.theme.padding.sm} solid ${(props) => props.theme.palette.blue};
    padding-left: ${(props) => props.theme.padding.md};
    padding-top: ${(props) => props.theme.padding.md};
    padding-bottom: ${(props) => props.theme.padding.md};
`;

const viewableTimePeriodOptions: string[] = [
    PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label,
    PeriodTypeOptions.find((x) => x.value === PeriodType.Days)!.label,
];

const StyledHighlightingSpan = styled.span`
    background-color: ${(props) => props.theme.palette.highlightColour};
`;

const StyledLoaderDiv = styled.div`
    text-align: center;
    width: 120px;
    height: 120px;
    background-image: url(${loaderImage});
    background-size: contain;
    display: block;
    margin: auto;
    background-repeat: no-repeat;
    opacity: 0.65;
`;

export const StyledCommentsFormLabel = styled(Form.Label)`
    text-align: right;
    font-weight: 800;
    color: ${(props) => props.theme.palette.secondary};
`;

const buildActionOperation = (
    showRowAction: boolean | undefined,
    actionOperation: ((item: any) => void) | undefined
): ((item: any) => void) | undefined => (showRowAction !== false ? actionOperation : undefined);

interface DataTableProps<TType, TPaginationType extends BasePaginationDto> {
    columns?: Column<TType>[];
    rows: TableRow<TType>[] | undefined;
    noResultsMessage?: string | undefined; // TODO: Make use of this so that we can bring "empty state handling" to all Data Tables
    searchText?: string;
    keyPrefix?: string;
    sortBy?: (columnName: string) => void;

    // TODO: We should rather have the DataTableProps accept a list of DataTableActions instead of the below, as it does not scale
    viewItem?: (metadata: TType) => void;
    searchItem?: (metadata: TType) => void;
    editItem?: (metadata: TType) => void;
    deleteItem?: (metadata: TType) => void;
    selectItem?: (metadata: TType) => void;
    selectAll?: (metadata: TType[]) => void;
    addChild?: (metadata: TType) => void;
    viewInHierarchy?: (metadata: TType) => void;
    selectedItem?: (metadata: TType) => boolean;
    selectedAllItems?: (metadatas: TType[]) => boolean;
    resolvedItem?: (metadata: TType) => boolean;
    isCheckDisabled?: boolean;
    handleColumnCheckBox?: (metadata: TType) => void;
    radioSelect?: RadioSelectProps;
    //TODO: Update input fields to use handleUpdatesToInputFields instead of handleUpdatesToRows to avoid state management and rather use form data
    handleUpdatesToRows?: (row: TableRow<TType> | undefined) => void;
    handleUpdatesToInputFields?: (
        event: React.FormEvent<HTMLFormElement>,
        rowMetadata: TType,
        columnMetadata: string
    ) => void;

    /** @deprecated No more support will be developed for this. Instead, this is to be phased out and the handling of links and ribbon props/functions are to be moved and conducted on a column-level, as opposed to a row-level */
    linkItem?: (metadata: TType) => void;
    ribbon?: JSX.Element;

    totalItems?: number;
    paginationDto?: TPaginationType;
    setPaginationDto?: (paginationDto: TPaginationType) => void;
    setRows?: (rows: TableRow<TType>[]) => void;

    nestedColumnNames?: {
        value: string;
        type: string;
    }[];
    editNestedItem?: (metadata: TType) => void;
    deleteNestedItem?: (metadata: TType) => void;
    linkNestedItem?: (metadata: TType) => void;
    nestedNoResultsMessage?: string | undefined;
    showNestedResults?: boolean | undefined;

    downloadAttachedFile?: (fileId: number) => void;
    attachedFilesLabel?: string;
    attachedFileDownloadData?: FileDownloadDto | null;

    uploadFileItem?: (metadata: TType) => void;
    downloadFileItem?: (metadata: TType) => void;

    handleDeleteItemComment?: (event: React.FormEvent<HTMLFormElement>, metadata: TType) => void;
    handleResolveItemComment?: (event: React.FormEvent<HTMLFormElement>, metadata: TType) => void;

    overrideItem?: (metadata: TType) => void;

    overrideNestedSection?: (metadata: TType) => void;
    cancelNestedSection?: (metadata: TType) => void;
    isLoading?: boolean;
    isSuccessful?: boolean;
}

export const DataTable = <TType, TPaginationType extends BasePaginationDto>({
    columns,
    rows,
    noResultsMessage,
    searchText,
    keyPrefix,
    sortBy,
    viewItem,
    searchItem,
    linkItem,
    editItem,
    deleteItem,
    selectItem,
    addChild,
    viewInHierarchy,
    selectedItem,
    selectedAllItems,
    selectAll,
    resolvedItem,
    isCheckDisabled,
    handleColumnCheckBox: checkColumnOfItem,
    radioSelect,
    ribbon,
    totalItems,
    paginationDto,
    setPaginationDto,
    handleUpdatesToRows,
    handleUpdatesToInputFields,
    setRows,
    nestedColumnNames,
    linkNestedItem,
    nestedNoResultsMessage,
    showNestedResults,
    editNestedItem,
    deleteNestedItem,
    downloadAttachedFile,
    attachedFilesLabel,
    attachedFileDownloadData,
    uploadFileItem,
    downloadFileItem,
    handleDeleteItemComment,
    handleResolveItemComment,
    overrideItem,
    overrideNestedSection,
    cancelNestedSection,
    isLoading,
    isSuccessful,
}: DataTableProps<TType, TPaginationType>): JSX.Element => {
    if ((isLoading !== undefined && isLoading) || (isSuccessful !== undefined && !isSuccessful)) {
        return <StyledLoaderDiv />;
    }

    const { t } = useTranslation("translation");
    const handleDisplayingOfNestedRows = (row: TableRow<TType>): void => {
        let updatedRowsData = rows ? rows.slice() : [];
        updatedRowsData.map((r) =>
            r.metadata === row.metadata
                ? (r.isNestedRowsDisplayed = !getIsNestedRowsDisplayedValue(r))
                : r
        );
        setRows && setRows(updatedRowsData);
    };

    const getIsNestedRowsDisplayedValue = (row: TableRow<TType>): boolean => {
        if (row.isNestedRowsDisplayed) {
            return row.isNestedRowsDisplayed;
        }
        return false;
    };

    const translate = (value: string): string => {
        return translateText(t, value, keyPrefix);
    };

    const handleDisplayingOfAttachedFiles = (row: TableRow<TType>): void => {
        let updatedRowsData = rows ? rows.slice() : [];
        updatedRowsData.map((r) =>
            r.metadata === row.metadata
                ? (r.isAttachedFilesDisplayed = !getIsAttachedFilesDisplayedValue(r))
                : r
        );
        setRows && setRows(updatedRowsData);
    };

    const getIsAttachedFilesDisplayedValue = (row: TableRow<TType>): boolean => {
        if (row.isAttachedFilesDisplayed) {
            return row.isAttachedFilesDisplayed;
        }
        return false;
    };

    const handleDisplayingOfDeleteCommentForm = (metadata: TType): void => {
        let updatedRowsData = rows ? rows.slice() : [];
        updatedRowsData.map((r) =>
            r.metadata === metadata
                ? (r.isNestedDeleteCommentFormDisplayed = !getIsNestedDeleteCommentFormDisplayed(r))
                : r
        );
        setRows && setRows(updatedRowsData);
    };

    const getIsNestedDeleteCommentFormDisplayed = (row: TableRow<TType>): boolean => {
        if (row.isNestedDeleteCommentFormDisplayed) {
            return row.isNestedDeleteCommentFormDisplayed;
        }
        return false;
    };

    const handleDisplayingOfResolveCommentForm = (metadata: TType): void => {
        let updatedRowsData = rows ? rows.slice() : [];
        updatedRowsData.map((r) =>
            r.metadata === metadata
                ? (r.isNestedResolveColumnDisplayed = !getIsNestedResolveCommentFormDisplayed(r))
                : r
        );
        setRows && setRows(updatedRowsData);
    };

    const getIsNestedResolveCommentFormDisplayed = (row: TableRow<TType>): boolean => {
        if (row.isNestedResolveColumnDisplayed) {
            return row.isNestedResolveColumnDisplayed;
        }
        return false;
    };

    const getUpdatedRowData = (
        row: TableRow<TType>,
        col: Column<TType>,
        newColValue: string,
        inputType: string
    ): TableRow<TType> | undefined => {
        let updatedRowsData = rows ? rows.slice() : [];

        if (inputType === "PeriodInput") {
            updatedRowsData.map((r) =>
                r.metadata === row.metadata
                    ? r.columns?.map((c) =>
                          c.metadata === col.metadata ? (c.periodTypeValue = newColValue) : c
                      )
                    : r
            );
        } else {
            updatedRowsData.map((r) =>
                r.metadata === row.metadata
                    ? r.columns?.map((c) =>
                          c.metadata === col.metadata ? (c.value = newColValue) : c
                      )
                    : r
            );
        }

        setRows && setRows(updatedRowsData);

        return updatedRowsData.find((r) => r.metadata === row.metadata);
    };

    const renderColumn = (column: Column<TType>): ReactElement => {
        switch (column.type) {
            case DataTableColumnTypes.Sort:
                return (
                    <StyledSortGridP onClick={() => sortBy && sortBy(column.value)}>
                        {translate(column.value)}
                    </StyledSortGridP>
                );
            case DataTableColumnTypes.CheckAll:
                return (
                    <StyledGridP>
                        <SbCheckField
                            type={'checkbox'}
                            label={translate(column.value)}
                            name={"selectAll"}
                            key={`selectAll`}
                            reverse={true}
                            defaultSelected={selectedAllItems && rows && selectedAllItems(rows.map(x => x.metadata).filter(x => x != undefined))}
                            onChangeHandler={() => {
                                if(selectAll && rows){
                                    selectAll(rows.map(x => x.metadata).filter(x => x != undefined))
                                }
                            }}
                        />
                    </StyledGridP>
                )
            default:
                return (
                    <StyledGridP>{translate(column.value)}</StyledGridP>
                );
        }
    };

    return (
        <ContentContainer>
            <StyledTable hover borderless>
                {columns && (
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>
                                    {renderColumn(column)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                )}

                {buildNoResultsView(rows, noResultsMessage)}

                <tbody>
                    {rows?.map((row, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                {buildResultsView(
                                    row,
                                    isCheckDisabled,
                                    t,
                                    checkColumnOfItem,
                                    linkItem,
                                    ribbon,
                                    handleDisplayingOfNestedRows,
                                    handleDisplayingOfAttachedFiles,
                                    handleUpdatesToRows,
                                    handleUpdatesToInputFields,
                                    getUpdatedRowData,
                                    searchText,
                                    resolvedItem
                                )}

                                {(viewItem ||
                                    searchItem ||
                                    editItem ||
                                    deleteItem ||
                                    selectItem ||
                                    addChild ||
                                    viewInHierarchy ||
                                    uploadFileItem ||
                                    downloadFileItem ||
                                    overrideItem) &&
                                    row.metadata && (
                                        <StyledTd>
                                            <DataTableAction
                                                metadata={row.metadata}
                                                selectedItem={
                                                    selectedItem && selectedItem(row.metadata)
                                                }
                                                radioSelect={radioSelect}
                                                viewItem={buildActionOperation(
                                                    row.showViewAction,
                                                    viewItem
                                                )}
                                                searchItem={buildActionOperation(
                                                    row.showSearchAction,
                                                    searchItem
                                                )}
                                                editItem={buildActionOperation(
                                                    row.showEditAction,
                                                    editItem
                                                )}
                                                deleteItem={buildActionOperation(
                                                    row.showDeleteAction,
                                                    handleDeleteItemComment
                                                        ? handleDisplayingOfDeleteCommentForm
                                                        : deleteItem
                                                )}
                                                selectItem={buildActionOperation(
                                                    row.showSelectAction,
                                                    selectItem
                                                )}
                                                addChild={buildActionOperation(
                                                    row.showAddChildAction,
                                                    addChild
                                                )}
                                                viewInHierarchy={buildActionOperation(
                                                    true,
                                                    viewInHierarchy
                                                )}
                                                uploadFileItem={buildActionOperation(
                                                    row.showUploadFileAction,
                                                    uploadFileItem
                                                )}
                                                downloadFileItem={buildActionOperation(
                                                    row.showDownloadFileAction,
                                                    downloadFileItem
                                                )}
                                                resolveItem={buildActionOperation(
                                                    row.showResolveAction,
                                                    handleResolveItemComment
                                                        ? handleDisplayingOfResolveCommentForm
                                                        : undefined
                                                )}
                                                resolvedItem={
                                                    resolvedItem && resolvedItem(row.metadata)
                                                }
                                                overrideItem={buildActionOperation(
                                                    row.showOverrideAction,
                                                    overrideItem
                                                )}
                                            />
                                        </StyledTd>
                                    )}
                            </tr>
                            {(showNestedResults || row.isNestedRowsDisplayed) && (
                                <tr>
                                    <StyledNestedTd colSpan={11}>
                                        <StyledTable>
                                            <StyledNestedDiv>
                                                <DataTable
                                                    columns={nestedColumnNames}
                                                    keyPrefix={keyPrefix}
                                                    rows={row.nestedRows}
                                                    deleteItem={deleteNestedItem}
                                                    linkItem={linkNestedItem}
                                                    editItem={editNestedItem}
                                                    handleUpdatesToRows={handleUpdatesToRows}
                                                    noResultsMessage={nestedNoResultsMessage}
                                                    setRows={setRows}
                                                    downloadAttachedFile={downloadAttachedFile}
                                                    attachedFileDownloadData={
                                                        attachedFileDownloadData
                                                    }
                                                />
                                            </StyledNestedDiv>
                                        </StyledTable>
                                        {(overrideNestedSection || cancelNestedSection) && (
                                            <EndAlignedDiv>
                                                {overrideNestedSection && (
                                                    <SaveButton
                                                        label={t("Override", { keyPrefix: Common })}
                                                        type={null}
                                                        onClick={() =>
                                                            overrideNestedSection(row.metadata!)
                                                        }
                                                    />
                                                )}
                                                {cancelNestedSection && (
                                                    <CancelButton
                                                        onClick={() =>
                                                            cancelNestedSection(row.metadata!)
                                                        }
                                                    />
                                                )}
                                            </EndAlignedDiv>
                                        )}
                                    </StyledNestedTd>
                                </tr>
                            )}
                            {row.isAttachedFilesDisplayed && (
                                <StyledNestedTd colSpan={11}>
                                    <StyledTable>
                                        <StyledAttachedFilesDiv>
                                            <FileGallery
                                                filesLabel={attachedFilesLabel}
                                                files={row.attachedFiles!}
                                                onDownloadFile={downloadAttachedFile!}
                                                fileDownloadData={attachedFileDownloadData!}
                                            />
                                        </StyledAttachedFilesDiv>
                                    </StyledTable>
                                </StyledNestedTd>
                            )}
                            {row.isNestedDeleteCommentFormDisplayed &&
                                handleDeleteItemComment &&
                                row.metadata && (
                                    <NestedRemoveOrResolveForm
                                        handleDeleteOrResolveItemComment={handleDeleteItemComment}
                                        metadata={row.metadata}
                                        inputFieldName={"deleteItemComment"}
                                        buttonLabel={t("RemoveFinding", { keyPrefix: Common })}
                                    />
                                )}
                            {row.isNestedResolveColumnDisplayed &&
                                handleResolveItemComment &&
                                row.metadata &&
                                resolvedItem &&
                                !resolvedItem(row.metadata) && (
                                    <NestedRemoveOrResolveForm
                                        handleDeleteOrResolveItemComment={handleResolveItemComment}
                                        metadata={row.metadata}
                                        inputFieldName={"resolveItemComment"}
                                        buttonLabel={t("ResolveFinding", { keyPrefix: Reports })}
                                    />
                                )}
                        </React.Fragment>
                    ))}
                </tbody>
            </StyledTable>
            <EndAlignedDiv>
                {totalItems && paginationDto && setPaginationDto ? (
                    <Paginator
                        totalItems={totalItems}
                        paginationDto={paginationDto}
                        setPaginationProps={setPaginationDto}
                    />
                ) : (
                    <></>
                )}
            </EndAlignedDiv>
        </ContentContainer>
    );
};

export const buildNoResultsView = <TType,>(
    rows: TableRow<TType>[] | undefined,
    noResultsMessage: string | null | undefined
): JSX.Element => {
    if (!rows?.length && rows!.length <= 0 && noResultsMessage) {
        return <NoDataStateDiv>{noResultsMessage}</NoDataStateDiv>;
    }

    return <></>;
};

export const buildResultsView = <TType,>(
    row: TableRow<TType>,
    isCheckDisabled: boolean | undefined,
    t: TFunction,
    checkColumnOfItem?: (metadata: TType) => void,
    linkItem?: (metadata: TType) => void,
    ribbon?: JSX.Element,
    handleDisplayingOfNestedRows?: (row: TableRow<TType>) => void,
    handleDisplayingOfAttachedFiles?: (row: TableRow<TType>) => void,
    handleUpdatesToRows?: (row: TableRow<TType> | undefined) => void,
    handleUpdatesToInputFields?: (
        event: React.FormEvent<HTMLFormElement>,
        rowMetadata: TType,
        columnMetadata: string
    ) => void,
    getUpdatedRowData?: (
        row: TableRow<TType>,
        col: Column<TType>,
        newColValue: string,
        inputType: string
    ) => TableRow<TType> | undefined,
    searchText?: string,
    resolvedItem?: (metadata: TType) => boolean
): JSX.Element[] => {
    const themeContext = useContext(ThemeContext);

    return row.columns.map((column, index) => (
        <React.Fragment key={index}>
            {column.type === DataTableColumnTypes.PeriodInput && (
                <td>
                    {handleUpdatesToRows && getUpdatedRowData && (
                        <>
                            <StyledInput
                                type="number"
                                key={`Number ${row.metadata ?? index}`}
                                value={column.value}
                                min={0}
                                max={999}
                                onChange={(newColValue) =>
                                    handleUpdatesToRows(
                                        getUpdatedRowData(
                                            row,
                                            column,
                                            newColValue.target.value,
                                            "NumberInput"
                                        )
                                    )
                                }
                            />
                            <SbSelect
                                styles={maxContentWidthSelectStyle}
                                name={"viewableTimePeriod"}
                                key={`Select ${row.metadata ?? index}`}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                searchable={false}
                                clearable={false}
                                items={valuesToReactSelectDtos(viewableTimePeriodOptions)}
                                itemLabel={(option: ReactSelectDto<string>) =>
                                    t(option.label, { keyPrefix: Common })
                                }
                                itemValue={(option: ReactSelectDto<string>) => option.value}
                                defaultSelectedItem={valuesToReactSelectDtos(
                                    viewableTimePeriodOptions
                                ).find((x) => x.value === column.periodTypeValue)}
                                onChange={(newColValue) =>
                                    handleUpdatesToRows(
                                        getUpdatedRowData(
                                            row,
                                            column,
                                            newColValue?.value ??
                                                PeriodTypeOptions.find(
                                                    (x) => x.value === PeriodType.Hours
                                                )!.label,
                                            "PeriodInput"
                                        )
                                    )
                                }
                            />
                        </>
                    )}
                </td>
            )}
            {column.type === DataTableColumnTypes.TimeInput && getUpdatedRowData && (
                <td>
                    {handleUpdatesToRows && (
                        <StyledInput
                            type="time"
                            key={`Time ${row.metadata ?? index}`}
                            value={column.value}
                            onChange={(newColValue) =>
                                handleUpdatesToRows(
                                    getUpdatedRowData(
                                        row,
                                        column,
                                        newColValue.target.value,
                                        "TimeInput"
                                    )
                                )
                            }
                        />
                    )}
                </td>
            )}
            {column.type === DataTableColumnTypes.DateTimeInput && (
                <>
                    <td>
                        <Form
                            onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
                                handleUpdatesToInputFields && row.metadata && column.metadata
                                    ? handleUpdatesToInputFields(
                                          event,
                                          row.metadata,
                                          column.metadata
                                      )
                                    : {};
                            }}
                        >
                            <Form.Group as={Row} className="mb-3">
                                <Col md="10">
                                    {resolvedItem && resolvedItem(row.metadata!) ? (
                                        <StyledDisabledInput disabled />
                                    ) : (
                                        <SbDateTimeField
                                            key={`DateTime ${row.metadata ?? index}`}
                                            name={`DateTime ${row.metadata!}`}
                                            defaultValue={
                                                resolvedItem && resolvedItem(row.metadata!)
                                                    ? null
                                                    : new Date(column.value)
                                            }
                                            disabled={resolvedItem && resolvedItem(row.metadata!)}
                                        />
                                    )}
                                </Col>
                                <Col md="auto">
                                    <SbButton
                                        variant={"secondary-transparent"}
                                        type="submit"
                                        label={t("Update", { keyPrefix: Common })}
                                        disabled={resolvedItem && resolvedItem(row.metadata!)}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </td>
                </>
            )}
            {column.type === DataTableColumnTypes.DisplayNestedTableButton &&
                handleDisplayingOfNestedRows && (
                    <td>
                        {!row.nestedRows ? (
                            column.value
                        ) : (
                            <div>
                                <SbButton
                                    hoverVariant="none"
                                    variant={"primary-transparent"}
                                    type={null}
                                    label={
                                        row.isNestedRowsDisplayed
                                            ? `${t("Hide", { keyPrefix: Common })}`
                                            : `${t("Show", { keyPrefix: Common })}`
                                    }
                                    icon={
                                        row.isNestedRowsDisplayed
                                            ? FaCaretSquareDown
                                            : FaCaretSquareRight
                                    }
                                    onClick={() => handleDisplayingOfNestedRows(row)}
                                    iconSize={"lg"}
                                />
                            </div>
                        )}
                    </td>
                )}
            {column.type === DataTableColumnTypes.DisplayAttachedFilesButton &&
                handleDisplayingOfAttachedFiles && (
                    <td>
                        {!row.attachedFiles ? (
                            column.value
                        ) : (
                            <div>
                                <SbButton
                                    hoverVariant="none"
                                    variant={"primary-transparent"}
                                    type={null}
                                    label={
                                        row.isAttachedFilesDisplayed
                                            ? `${t("Hide", { keyPrefix: Common })}`
                                            : `${t("Show", { keyPrefix: Common })}`
                                    }
                                    icon={
                                        row.isAttachedFilesDisplayed
                                            ? FaCaretSquareDown
                                            : FaCaretSquareRight
                                    }
                                    onClick={() => handleDisplayingOfAttachedFiles(row)}
                                    iconSize={"lg"}
                                />
                            </div>
                        )}
                    </td>
                )}
            {column.type === DataTableColumnTypes.Text && (
                <td>
                    {searchText ? (
                        highlightText(column.value, searchText)
                    ) : (
                        <FormattedString
                            rawString={column.value}
                            isSmallDetailsValue={false}
                            isDetailsValue={false}
                        />
                    )}
                </td>
            )}
            {column.type === DataTableColumnTypes.Check && (
                <td>
                    <SbCheckField
                        aria-label={index.toString()}
                        defaultSelected={column.value === "true" || column.value === "True"}
                        disabled={isCheckDisabled}
                        key={`Check field ${row.metadata}`}
                        onChangeHandler={() =>
                            row.metadata && checkColumnOfItem && checkColumnOfItem(row.metadata)
                        }
                        type={"checkbox"}
                        name={`Check field ${row.metadata}`}
                    />
                </td>
            )}
            {column.type === DataTableColumnTypes.Link &&
                linkItem && ( // TODO: Remove this way of handling links - linking is a column-based operation, it doesn't happen on a row level.
                    <StyledLinkTd onClick={() => row.metadata && linkItem(row.metadata as any)}>
                        {column.value}
                    </StyledLinkTd>
                )}
            {column.type === DataTableColumnTypes.Link && column.linkItemAction != undefined && (
                <StyledLinkTd onClick={() => row.metadata && column.linkItemAction!(row.metadata)}>
                    {column.value}
                </StyledLinkTd>
            )}
            {column.type === DataTableColumnTypes.LinkAndRibbon &&
                linkItem && ( // TODO: Remove this way of handling links and ribbons - these are column-based operations.
                    <StyledLinkAndRibbonTd
                        onClick={() => row.metadata && linkItem(row.metadata as any)}
                    >
                        {column.value}
                        {row.showRibbon && ribbon}
                    </StyledLinkAndRibbonTd>
                )}
            {column.type === DataTableColumnTypes.LinkAndRibbon &&
                column.linkItemAction != undefined && (
                    <StyledLinkAndRibbonTd
                        onClick={() => row.metadata && column.linkItemAction!(row.metadata)}
                    >
                        {column.value}
                        {column.showRibbon && ribbon}
                    </StyledLinkAndRibbonTd>
                )}
            {column.type === DataTableColumnTypes.IconLink &&
                (column.linkItemAction != undefined ? (
                    <StyledLinkTd
                        onClick={() => row.metadata && column.linkItemAction!(row.metadata)}
                    >
                        {column.icon != undefined &&
                            React.createElement(column.icon, {
                                color: themeContext!.palette.primary,
                                size: themeContext!.padding.lg,
                            })}
                    </StyledLinkTd>
                ) : (
                    <StyledLinkTd />
                ))}
        </React.Fragment>
    ));
};

const highlightText = (contentText: string, searchText: string): React.JSX.Element[] => {
    const regularExpression = new RegExp(`(${searchText})`, "gi");
    const parts = contentText.split(regularExpression);

    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {regularExpression.test(part) ? (
                <StyledHighlightingSpan>{part}</StyledHighlightingSpan>
            ) : (
                part
            )}
        </React.Fragment>
    ));
};
