import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { Response } from "../../responses/common/response-response";
import { softDeleteUserGroup } from "../../../data/services/users/user-groups-service";

export const useSoftDeleteUserGroup = (): UseMutationResult<Response<boolean>, HTTPError, number> => {
    const url = useUrl();

    return useMutation((userGroupId: number) => softDeleteUserGroup(url.baseUrl, userGroupId));
};
