import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { dissociateUser } from "../../../data/services/hierarchy/nodes-service";
import { getUserDetails } from "../../../data/services/users/users-service";
import { AssociateDissociateUserNodeDto } from "../../dtos/users/associate-dissociate-user-node-dto";
import { createUserDtoFromResponse, UserDto } from "../../dtos/users/user-dto";
import { AssociateDissociateUserNodeRequest } from "../../requests/users/associate-dissociate-user-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetUserDetails = (userId: number): UseQueryResult<UserDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getUserDetails", userId], () => getUserDetails(url.baseUrl, userId), {
        keepPreviousData: true,
        select: createUserDtoFromResponse,
        onError: errorResponseToDisplayHandler,
    });
};

export const useDissociateUser = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateUserNodeDto
> => {
    const url = useUrl();

    return useMutation((dto: AssociateDissociateUserNodeDto) =>
        dissociateUser(url.baseUrl, new AssociateDissociateUserNodeRequest(dto))
    );
};
