import {
    buildFilterByNodeSearchParameters,
    FilterByNodeRequest,
} from "../../../domain/requests/common/filter-by-node-request";
import { CreateChildNodeRequest } from "../../../domain/requests/hierarchy/create-child-node-request";
import { EditNodeRequest } from "../../../domain/requests/hierarchy/edit-node-request";
import FilterAssociatedUserGroupNodesRequest from "../../../domain/requests/hierarchy/filter-associated-user-group-nodes-request";
import FilterAssociatedUserNodesRequest from "../../../domain/requests/hierarchy/filter-associated-user-nodes-request";
import {
    buildFilterSearchParameters,
    FilterChildNodesRequest,
} from "../../../domain/requests/hierarchy/filter-child-nodes-request";
import {
    buildFilterNodeHierarchySearchParameters,
    FilterNodeHierarchyRequest,
} from "../../../domain/requests/hierarchy/filter-node-hierarchy-request";
import {
    buildFilterNodeTypesSearchParameters,
    FilterNodesRequest,
} from "../../../domain/requests/hierarchy/filter-node-request";
import LinkableNodesRequest from "../../../domain/requests/hierarchy/linkable-nodes-request";
import { MoveNodeRequest } from "../../../domain/requests/hierarchy/move-node-request";
import { SuspendNodeRequest } from "../../../domain/requests/hierarchy/suspend-node-request";
import {
    buildUserGroupNodeAssociationDissociationSearchParameters,
    UserGroupNodeAssociationDissociationRequest,
} from "../../../domain/requests/hierarchy/user-group-node-association-dissociation-request";
import {
    buildUserNodeAssociationDissociationSearchParameters,
    UserNodeAssociationDissociationRequest,
} from "../../../domain/requests/hierarchy/user-node-association-dissociation-request";
import { NonWorkingDaySetNodeTypeResponse } from "../../../domain/requests/non-working-days/non-working-day-set-node-type-response";
import { AssociateDissociateUserGroupNodeRequest } from "../../../domain/requests/user-groups/associate-dissociate-user-group-node-request";
import { UserNodeSuspensionRequest } from "../../../domain/requests/users/suspend-user-from-all-nodes-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { AssignedUsersToNodeResponse } from "../../../domain/responses/hierarchy/assigned-users-to-node-response";
import { AssociatedUserGroupNodesResponse } from "../../../domain/responses/hierarchy/associated-user-group-node-response";
import { AssociatedUserNodesResponse } from "../../../domain/responses/hierarchy/associated-user-nodes-response";
import { BaseNodeDetailsResponse } from "../../../domain/responses/hierarchy/base-node-details-response";
import { BaseNodeResponse } from "../../../domain/responses/hierarchy/base-node-response";
import { FunctionUserNodesResponse } from "../../../domain/responses/hierarchy/function-user-nodes-response";
import { LinkableNodeResponse } from "../../../domain/responses/hierarchy/linkable-node-response";
import { NodeDescriptionDetailsResponse } from "../../../domain/responses/hierarchy/node-description-details-response";
import { NodeDetailsResponse } from "../../../domain/responses/hierarchy/node-details-response";
import { NodeHierarchyResponse } from "../../../domain/responses/hierarchy/node-hierarchy-response";
import { UserNodeSuspensionDetailsResponse } from "../../../domain/responses/users/user-node-suspension-details-response";
import client from "../../http-client";

const route = "nodes";

export const filterNodes = async (
    prefixUrl: string,
    request: FilterNodesRequest
): Promise<Response<PaginationResponse<BaseNodeResponse>>> => {
    const searchParams = buildFilterNodeTypesSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterAssociatedNonWorkingDaySetNodes = async (
    prefixUrl: string,
    nonWorkingDaySetId: number
): Promise<Response<NonWorkingDaySetNodeTypeResponse[]>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("nonWorkingDaySetId", nonWorkingDaySetId.toString());

    return await client
        .get(`${route}/non-working-day-set-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterUserNodes = async (
    prefixUrl: string,
    userId: number
): Promise<Response<NodeDescriptionDetailsResponse[]>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("userId", userId.toString());

    return await client
        .get(`${route}/user-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterOrganisationalUserNodes = async (
    prefixUrl: string,
    userId: number
): Promise<Response<BaseNodeDetailsResponse[]>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("userId", userId.toString());

    return await client
        .get(`${route}/organisational-user-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterFunctionUserNodes = async (
    prefixUrl: string,
    userId: number
): Promise<Response<FunctionUserNodesResponse>> => {
    const searchParams = new URLSearchParams();
    searchParams.set("userId", userId.toString());

    return await client
        .get(`${route}/function-user-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterNodesWithPath = async (
    prefixUrl: string,
    request: FilterNodesRequest
): Promise<Response<PaginationResponse<BaseNodeDetailsResponse>>> => {
    const searchParams = buildFilterNodeTypesSearchParameters(request);

    return await client
        .get(`${route}/filter-with-path`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterNodeHierarchy = async (
    prefixUrl: string,
    request: FilterNodeHierarchyRequest
): Promise<Response<NodeHierarchyResponse>> => {
    const searchParams = buildFilterNodeHierarchySearchParameters(request);

    return await client
        .get(`${route}/hierarchy`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterNodeHierarchyUpwards = async (
    prefixUrl: string,
    request: FilterNodeHierarchyRequest
): Promise<Response<NodeHierarchyResponse>> => {
    const searchParams = buildFilterNodeHierarchySearchParameters(request);

    return await client
        .get(`${route}/hierarchy-upwards`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getFunctionUserNodes = async (
    prefixUrl: string
): Promise<Response<BaseNodeDetailsResponse[]>> =>
    await client
        .get(`${route}/function-user-nodes`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getNodeDetails = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<NodeDetailsResponse>> =>
    await client
        .get(`${route}/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterChildNodes = async (
    prefixUrl: string,
    request: FilterChildNodesRequest
): Promise<Response<PaginationResponse<BaseNodeResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/children/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const editNode = async (
    prefixUrl: string,
    request: EditNodeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const filterAssignedUsersToNode = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<AssignedUsersToNodeResponse>> =>
    await client
        .get(`${route}/${nodeId}/users`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterLinkedNodes = async (
    prefixUrl: string,
    request: FilterByNodeRequest
): Promise<Response<PaginationResponse<BaseNodeResponse>>> => {
    const searchParams = buildFilterByNodeSearchParameters(request);

    return await client
        .get(`${route}/linked-nodes`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const associateUserToNode = async (
    prefixUrl: string,
    request: UserNodeAssociationDissociationRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/associate-user`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateUserGroupToNode = async (
    prefixUrl: string,
    request: AssociateDissociateUserGroupNodeRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/associate-user-group`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const createChildNode = async (
    prefixUrl: string,
    request: CreateChildNodeRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getNodeDescriptionDetails = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<NodeDescriptionDetailsResponse>> =>
    await client
        .get(`${route}/description-details/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const moveNode = async (
    prefixUrl: string,
    request: MoveNodeRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/move`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const promoteNode = async (prefixUrl: string, nodeId: number): Promise<Response<boolean>> =>
    await client
        .put(`${route}/promote/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const demoteNode = async (prefixUrl: string, nodeId: number): Promise<Response<boolean>> =>
    await client
        .put(`${route}/demote/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const activateNode = async (prefixUrl: string, nodeId: number): Promise<Response<boolean>> =>
    await client
        .put(`${route}/activate/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const suspendNode = async (
    prefixUrl: string,
    request: SuspendNodeRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/suspend`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const suspendUserNode = async (
    prefixUrl: string,
    request: UserNodeSuspensionRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/suspend-user-from-all-nodes`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getUserNodeSuspensionDetails = async (
    prefixUrl: string,
    userId: number
): Promise<Response<UserNodeSuspensionDetailsResponse>> => {
    return await client
        .get(`${route}/user-node-suspension-details/${userId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
};

export const cancelUserNodeSuspension = async (
    prefixUrl: string,
    userNodeSuspensionId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/cancel-user-node-suspension/${userNodeSuspensionId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateUser = async (
    prefixUrl: string,
    request: UserNodeAssociationDissociationRequest
): Promise<Response<boolean>> => {
    const searchParams = buildUserNodeAssociationDissociationSearchParameters(request);

    return await client
        .put(`${route}/dissociate-user`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const dissociateUserGroupNode = async (
    prefixUrl: string,
    request: UserGroupNodeAssociationDissociationRequest
): Promise<Response<boolean>> => {
    const searchParams = buildUserGroupNodeAssociationDissociationSearchParameters(request);

    return await client
        .put(`${route}/dissociate-user-group`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getCanSoftDeleteNode = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<boolean>> =>
    await client
        .get(`${route}/can-soft-delete/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const softDeleteNode = async (
    prefixUrl: string,
    nodeId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/soft-delete/${nodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterAssociatedUserNodes = async (
    prefixUrl: string,
    request: FilterAssociatedUserNodesRequest
): Promise<Response<PaginationResponse<AssociatedUserNodesResponse>>> => {
    const searchParams = request.buildSearchParameters();

    return await client
        .get(`${route}/associated-user-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterAssociatedUserGroupNodes = async (
    prefixUrl: string,
    request: FilterAssociatedUserGroupNodesRequest
): Promise<Response<PaginationResponse<AssociatedUserGroupNodesResponse>>> => {
    const searchParams = request.buildSearchParameters();

    return await client
        .get(`${route}/associated-user-group-nodes/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getLinkableNodes = async (
    prefixUrl: string,
    request: LinkableNodesRequest
): Promise<Response<LinkableNodeResponse[]>> => {
    const searchParams = request.buildSearchParameters();

    return await client
        .get(`${route}/linkable-nodes`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
