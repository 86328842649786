import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { Response } from "../../responses/common/response-response";
import { updateUserGroupDetails } from "../../../data/services/users/user-groups-service";
import { UserGroupRequest } from "../../requests/users/user-group-details-request";
import { EditUserGroupDto } from "../../dtos/user-groups/user-group-dto";

export const useUpdateUserGroupDetails = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditUserGroupDto
> => {
    const url = useUrl();

    return useMutation((dto: EditUserGroupDto) => {
        return updateUserGroupDetails(url.baseUrl, new UserGroupRequest(dto));
    });
};