import { createPaginationDto, defaultPaginationDto, PaginationDto } from "../common/pagination-dto";

export interface FilterUserGroupsDto extends PaginationDto {
    userId: number | null;
    name: string | null;
    email: string | null;
}

export const createFilterUserGroupsDto = (
    userId: number | null,
    name: string | null,
    email: string | null,
    pageNumber: number,
    pageSize: number,
    sortByColumn: string | null,
    sortByDescending: boolean
): FilterUserGroupsDto => ({
    userId: userId,
    name: name,
    email: email,
    ...createPaginationDto(pageNumber, pageSize, sortByColumn, sortByDescending),
});

export const defaultFilterUserGroupsDto = (): FilterUserGroupsDto => {
    const { pageNumber, pageSize, sortByColumn, sortByDescending } = defaultPaginationDto;

    return createFilterUserGroupsDto(
        null,
        null,
        null,
        pageNumber,
        pageSize,
        sortByColumn,
        sortByDescending
    );
};
