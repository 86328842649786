import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { EditNodeTypeValueEvent } from "../../../core/constants/application-insights-events";
import {
    Common,
    NodeTypes,
    NodeTypeValues,
    Roles,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import TimeZoneDetailsDto from "../../../domain/dtos/timezone/time-zone-details-dto";
import { HierarchyAssignable } from "../../../domain/enums/hierarchy/HierarchyAssignable";
import { splitCamelCase } from "../../../domain/helpers/split-camel-case/spilt-camel-case";
import {
    createEditParameters,
    useEditNodeTypeValue,
    useGetNodeTypeValueDetails,
    useGetTimeZones,
} from "../../../domain/viewmodels/hierarchy/edit-node-type-value-viewmodel";
import { useGetNodeTypeDetails } from "../../../domain/viewmodels/hierarchy/edit-node-type-viewmodel";
import { useGetRoleRestrictedNodes } from "../../../domain/viewmodels/hierarchy/role-restricted-node-viewmodel";
import { useGetRoles } from "../../../domain/viewmodels/roles/role-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { SbAlert } from "../../atoms/SbAlert";

const EditNodeTypeValueContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const toast = useToast();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: NodeTypeValues });

    const nodeTypeValueId = Number(useParams().nodeTypeValueId);
    const [nodeTypeId, setNodeTypeId] = useState<number>(-1);
    const [roleIds, setRoleIds] = useState<number[]>([]);
    const getNodeTypeValueDetails = useGetNodeTypeValueDetails(nodeTypeValueId);
    const getRoles = useGetRoles();
    const getNodeTypeDetails = useGetNodeTypeDetails(nodeTypeId);
    const getRoleRestrictedNodes = useGetRoleRestrictedNodes(nodeTypeValueId);

    const getNodeTypeDetailsData = getNodeTypeDetails.data;
    const getNodeTypeValueDetailsData = getNodeTypeValueDetails.data;
    const getRolesData = getRoles.data;
    const getRoleRestrictedNodesData = getRoleRestrictedNodes.data;

    const nodeTypeContainsTimeZoneCode = getNodeTypeValueDetailsData
        ? getNodeTypeValueDetailsData.nodeType.containsTimeZoneCode
        : null;

    const getTimeZones = useGetTimeZones(nodeTypeContainsTimeZoneCode);

    const editNodeTypeValue = useEditNodeTypeValue();

    useLoader(
        areQueriesLoading([
            getNodeTypeValueDetails,
            getTimeZones,
            getNodeTypeDetails,
            getRoles,
            getRoleRestrictedNodes,
        ]) || isMutationLoading(editNodeTypeValue),
        EditNodeTypeValueContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualStructure);
    }, []);

    useEffect(() => {
        setNodeTypeId(getNodeTypeValueDetails.data?.nodeType.nodeTypeId ?? -1);
    }, [getNodeTypeValueDetails.data]);

    useEffect(() => {
        setRoleIds(getRoleRestrictedNodes.data?.map((x) => x.roleId) ?? []);
    }, [getRoleRestrictedNodes.data]);

    const onRoleIdChangeHandler = (roleId: number) => {
        const selectedRoleIds = [...roleIds];

        const index = selectedRoleIds.indexOf(roleId);
        if (index > -1) {
            selectedRoleIds.splice(index, 1);
        } else {
            selectedRoleIds.push(roleId);
        }

        setRoleIds(selectedRoleIds);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const editParameters = createEditParameters(
            nodeTypeValueId,
            new FormData(event.currentTarget),
            nodeTypeContainsTimeZoneCode ? getTimeZones.data! : null,
            roleIds
        );

        editNodeTypeValue.mutate(editParameters, {
            onSuccess: async () => {
                trackAppInsightsEvent(auth.email, window.location.href, EditNodeTypeValueEvent);

                toast.addToast(
                    createSuccessToastProps([t("UpdateHierarchyLevelValueSuccessMessage")])
                );
                navigate(`${getPath(AccordionTitles.VisualStructureValues)}/${nodeTypeValueId}`);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    EditNodeTypeValueEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const areQueriesSuccessful = (): boolean =>
        isQuerySuccessful(getNodeTypeValueDetails) &&
        isQuerySuccessful(getNodeTypeValueDetails) &&
        isQuerySuccessful(getNodeTypeDetails) &&
        isQuerySuccessful(getRoles) &&
        isQuerySuccessful(getRoleRestrictedNodes) &&
        isGetTimeZonesSuccessfulWhenRequired();

    const isGetTimeZonesSuccessfulWhenRequired = (): boolean => {
        if (nodeTypeContainsTimeZoneCode) {
            return isQuerySuccessful(getTimeZones);
        }

        return true;
    };

    const buildCodeField = (): JSX.Element =>
        nodeTypeContainsTimeZoneCode ? (
            <SbFormSelectFieldGroup
                name={"code"}
                label={t("Code", { keyPrefix: Common })}
                placeholderText={t("SelectTimeZone")}
                searchable
                clearable={false}
                items={getTimeZones.data}
                defaultSelectedItem={getDefaultSelectedTimeZone()}
                required
                itemDisplayText={(option: TimeZoneDetailsDto) => option.displayName} //TODO: Add translations for dynamic data
            />
        ) : (
            <SbFormTextFieldGroup
                name="code"
                label={t("Code", { keyPrefix: Common })}
                maxLength={50}
                type="text"
                defaultValue={getNodeTypeValueDetailsData!.code}
            />
        );

    const buildRoleRestriction = (): JSX.Element => {
        return (
            <SbFormCheckFieldGroup
                fieldLabel={t("RoleRestrictions", { keyPrefix: Roles })}
                type="checkbox"
                values={
                    getRolesData?.map((x) => ({
                        name: x.roleId.toString(),
                        label: splitCamelCase(x.name),
                        defaultSelected: getRoleRestrictedNodesData?.some(
                            (y) => y.roleId == x.roleId
                        ),
                        onChangeHandler: (roleId) => {
                            onRoleIdChangeHandler(Number(roleId));
                        },
                    })) ?? []
                }
            />
        );
    };

    const getDefaultSelectedTimeZone = (): TimeZoneDetailsDto | null =>
        getTimeZones.data!.find((x) => x.timeZoneId === getNodeTypeValueDetailsData!.code) ?? null;

    return (
        <>
            <PageHeading>{t("NodeTypeValueEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            <SbAlert variant="warning" text={t("EditHierarchyLevelValue")} />

            {areQueriesSuccessful() && (
                <TextTitledPanel title={t("EditNodeTypeValue")}>
                    <Form onSubmit={handleSubmit}>
                        <SbFormTextFieldGroup
                            name="nodeType"
                            label={t("NodeType", { keyPrefix: NodeTypes })}
                            type="text"
                            defaultValue={getNodeTypeValueDetailsData!.nodeType.name}
                            required
                            disabled
                        />

                        {buildCodeField()}

                        <SbFormTextFieldGroup
                            name="value"
                            label={t("Value", { keyPrefix: Common })}
                            type="text"
                            maxLength={100}
                            defaultValue={getNodeTypeValueDetailsData!.value}
                            required
                        />

                        <SbFormTextFieldGroup
                            name="description"
                            label={t("Description", { keyPrefix: Common })}
                            type="text"
                            maxLength={250}
                            defaultValue={getNodeTypeValueDetailsData!.description}
                        />

                        {getNodeTypeDetailsData?.hierarchyAssignables.some(
                            (x) => x == HierarchyAssignable.ActionItemTypes
                        ) && buildRoleRestriction()}

                        <EndAlignedDiv>
                            <SaveButton type="submit" />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </Form>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditNodeTypeValueContainer;
