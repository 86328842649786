import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterAssociatedUserGroupNodes } from "../../../data/services/hierarchy/nodes-service";
import { getUserGroupDetails } from "../../../data/services/users/user-groups-service";
import { filterAssociatedUserGroupUsers } from "../../../data/services/users/users-service";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import {
    createUserGroupDtoFromUserGroupResponse,
    UserGroupDto,
} from "../../dtos/users/user-group-dto";
import FilterAssociatedUserGroupNodesRequest from "../../requests/hierarchy/filter-associated-user-group-nodes-request";
import FilterAssociatedUserGroupUsersRequest from "../../requests/users/filter-associated-user-group-users-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { AssociatedUserGroupNodesResponse } from "../../responses/hierarchy/associated-user-group-node-response";
import { BaseUserResponse } from "../../responses/users/base-user-response";
import { UserGroupResponse } from "../../responses/users/user-group-response";

interface UserGroupUserAssociationParameters {
    userGroupId: number;
    userId: number;
}

export const createUserGroupUserAssociationParameters = (
    userGroupId: number,
    userId: number
): UserGroupUserAssociationParameters => ({
    userGroupId,
    userId,
});

export const useGetUserGroupDetails = (
    userGroupId: number
): UseQueryResult<UserGroupDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getUserGroupDetails", userGroupId],

        () => getUserGroupDetails(url.baseUrl, userGroupId),

        {
            select: (response: Response<UserGroupResponse>) =>
                createUserGroupDtoFromUserGroupResponse(response.data),
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterAssociatedUserGroupNodes = (
    userGroupId: number,
    associatedNodesPaginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAssociatedUserGroupNodesFilter", userGroupId, associatedNodesPaginationDto],
        () =>
            filterAssociatedUserGroupNodes(
                url.baseUrl,
                new FilterAssociatedUserGroupNodesRequest(userGroupId, associatedNodesPaginationDto)
            ),
        {
            enabled: auth.isAdmin ? auth.isImpersonating : true,
            select: (response: Response<PaginationResponse<AssociatedUserGroupNodesResponse>>) =>
                toAssociatedUserGroupNodesToDataTableRows(response, navigate),
            keepPreviousData: true,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toAssociatedUserGroupNodesToDataTableRows = (
    response: Response<PaginationResponse<AssociatedUserGroupNodesResponse>>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.nodeId,
        columns: [
            {
                value: x.longDescription,
                type: DataTableColumnTypes.Link,
                linkItemAction: (nodeId: number) => {
                    navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`);
                },
            },
            {
                value: x.path,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

export const useFilterAssociatedUserGroupUsers = (
    userGroupId: number,
    associatedUsersPaginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsersByUserGroup", userGroupId, associatedUsersPaginationDto],
        () =>
            filterAssociatedUserGroupUsers(
                url.baseUrl,
                new FilterAssociatedUserGroupUsersRequest(userGroupId, associatedUsersPaginationDto)
            ),
        {
            enabled: auth.isAdmin ? auth.isImpersonating : true,
            select: (response: Response<PaginationResponse<BaseUserResponse>>) =>
                toAssociatedUserGroupUsersToDataTableRows(response, navigate),
            keepPreviousData: true,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toAssociatedUserGroupUsersToDataTableRows = (
    response: Response<PaginationResponse<BaseUserResponse>>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.userId,
        columns: [
            {
                value: `${x.firstName} ${x.lastName}`,
                type: DataTableColumnTypes.Link,
                linkItemAction: (userId: number) =>
                    navigate(`${getPath(AccordionTitles.Users)}/${userId}`),
            },
            {
                value: `${x.emailAddress}`,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
