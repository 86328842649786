import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Common, NodeTypes, NodeTypeValues } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { hasRoleTypeInGroup, NodeTypeValueRoleGroup } from "../../../domain/enums/Roles";
import { useGetNodeTypeValueDetails } from "../../../domain/viewmodels/hierarchy/node-type-value-details-viewmodel";
import { SbLink, ViewLink } from "../../atoms/SbLink";
import { SbButtonLink } from "../../atoms/SbButton";
import { FaEdit } from "react-icons/fa";
import SbModal from "../../molecules/SbModal";


const NodeTypeValueDetailsContainer: React.FC = () => {
    const nodeTypeValueId = Number(useParams().nodeTypeValueId);

    const menu = useMenu();
    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: NodeTypeValues });
    const [showEditHierarchyLevelValueModal, setShowEditHierarchyLevelValueModal] = useState(false);

    const getNodeTypeValueDetails = useGetNodeTypeValueDetails(nodeTypeValueId);

    useLoader(isQueryLoading(getNodeTypeValueDetails), NodeTypeValueDetailsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualStructure);
    }, []);

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {hasRoleTypeInGroup(auth.userRoles, NodeTypeValueRoleGroup.WriteRoles) &&
                    buildEditLink()}
                <ViewLink
                    label={t("NodeType", { keyPrefix: NodeTypes })}
                    navigateTo={`${getPath(AccordionTitles.VisualStructure)}/${
                        getNodeTypeValueDetails.data!.nodeType.nodeTypeId
                    }`}
                />
            </>
        );
    };

    const handleConfirm = (): void => {
        navigate(`${getPath(
            AccordionTitles.VisualStructureValues
        )}/${nodeTypeValueId}/edit`);
    }

    const buildEditLink = (): boolean | JSX.Element =>
        hasRoleTypeInGroup(auth.userRoles, NodeTypeValueRoleGroup.WriteRoles) && (
            <SbButtonLink
                    variant="primary-transparent"
                    type="button"
                    label={t("Edit", { keyPrefix: Common })}
                    icon={FaEdit}
                    iconSize={"xl"}
                    hoverVariant="none"
                    alignItemsCenter
                    onClick={() => setShowEditHierarchyLevelValueModal(true)}
            />
        );

    return (
        <>
            <PageHeading>{t("NodeTypeValueDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getNodeTypeValueDetails) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>
                                    {t("NodeType", { keyPrefix: NodeTypes })}
                                </DetailsLabel>
                                <DetailsLabel>{t("Code", { keyPrefix: Common })}</DetailsLabel>
                                <DetailsLabel>{t("Value", { keyPrefix: Common })}</DetailsLabel>
                                <DetailsLabel>
                                    {t("Description", { keyPrefix: Common })}
                                </DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>
                                    <SbLink
                                        variant="primary"
                                        label={getNodeTypeValueDetails.data!.nodeType.name}
                                        navigateTo={`${getPath(AccordionTitles.VisualStructure)}/${
                                            getNodeTypeValueDetails.data!.nodeType.nodeTypeId
                                        }`}
                                    />
                                </DetailsValue>
                                <DetailsValue>
                                    {getNodeTypeValueDetails.data!.code ?? "-"}
                                </DetailsValue>
                                <DetailsValue>{getNodeTypeValueDetails.data!.value}</DetailsValue>
                                <DetailsValue>
                                    {getNodeTypeValueDetails.data!.description ?? "-"}
                                </DetailsValue>
                            </Col>
                        </Row>

                        <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                    </ContentContainer>
                    <SectionVerticalSpace />

                    <SbModal
                        title={t("EditHierarchy", { keyPrefix: NodeTypes })}
                        body={t("AreYouSure", { keyPrefix: Common })}
                        primaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                        secondaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                        onPrimaryButtonClicked={handleConfirm}
                        isVisible={showEditHierarchyLevelValueModal}
                        updateIsVisible={setShowEditHierarchyLevelValueModal}
                    />
                </>
            )}
        </>
    );
};

export default NodeTypeValueDetailsContainer;
