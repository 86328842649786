import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { filterHierarchyTypes } from "../../../data/services/hierarchy/hierarchy-types-service";
import { associateUserGroupToNode } from "../../../data/services/hierarchy/nodes-service";
import { getUserGroupDetails } from "../../../data/services/users/user-groups-service";
import { HierarchyTypeDto, toHierarchyTypeDtos } from "../../dtos/hierarchy/hierarchy-type-dto";
import { AssociateDissociateUserGroupNodeDto } from "../../dtos/user-groups/associate-dissociate-user-group-node-dto";
import { toUserGroupDto, UserGroupDto } from "../../dtos/users/user-group-dto";
import { createBasePaginationRequest } from "../../requests/common/base-pagination-request";
import { createFilterHierarchyTypesRequest } from "../../requests/hierarchy/filter-hierarchy-types-request";
import { emptySearchHierarchyTypesRequest } from "../../requests/hierarchy/search-hierarchy-types-request";
import { createUserGroupNodeAssociationDissociationRequest } from "../../requests/hierarchy/user-group-node-association-dissociation-request";
import { Response } from "../../responses/common/response-response";
import { UserGroupResponse } from "../../responses/users/user-group-response";

export const useGetUserGroupDetails = (
    userGroupId: number
): [UseQueryResult<UserGroupDto, HTTPError>, UseQueryResult<HierarchyTypeDto[], HTTPError>] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getUserGroupDetails", userGroupId],
                queryFn: () => getUserGroupDetails(url.baseUrl, userGroupId),
                select: (response: Response<UserGroupResponse>) => toUserGroupDto(response.data),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterHierarchyTypes"],
                queryFn: () =>
                    filterHierarchyTypes(
                        url.baseUrl,
                        createFilterHierarchyTypesRequest(
                            createBasePaginationRequest(1, 1000),
                            emptySearchHierarchyTypesRequest()
                        )
                    ),
                select: toHierarchyTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [UseQueryResult<UserGroupDto, HTTPError>, UseQueryResult<HierarchyTypeDto[], HTTPError>];
};

export const useAssociateUserGroupToNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateUserGroupNodeDto
> => {
    const url = useUrl();

    return useMutation((dto: AssociateDissociateUserGroupNodeDto) =>
        associateUserGroupToNode(
            url.baseUrl,
            createUserGroupNodeAssociationDissociationRequest(dto)
        )
    );
};
