import { Role } from "./Roles";

export const MenuRoleGroup = {
    Admin: {
        Dashboard: [Role.SystemAdmin],
        Library: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist, Role.TeamsManager],
        Checklists: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist, Role.TeamsManager],
        QuestionSets: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ProductSpecialist,
            Role.TeamsManager,
        ],
        Questions: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist, Role.TeamsManager],
        QuestionCategories: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist],
        AnswerTypes: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ProductSpecialist,
            Role.TeamsManager,
        ],
        ReferentialLinkTypes: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist],
        ChecklistImport: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist],
        Hierarchy: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ProductSpecialist,
            Role.ActionTrackerAdmin,
            Role.TeamsManager,
        ],
        VisualTree: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ActionTrackerAdmin,
            Role.ProductSpecialist,
            Role.TeamsManager,
            Role.ActionItemCapturer,
        ],
        Search: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ProductSpecialist,
            Role.TeamsManager,
            Role.TeamLead,
            Role.ActionItemCapturer,
            Role.ActionTrackerAdmin,
        ],
        VisualStructure: [Role.SystemAdmin, Role.TenantAdmin],
        HierarchyAdmin: [Role.SystemAdmin, Role.TenantAdmin],
        HierarchyImport: [Role.SystemAdmin, Role.TenantAdmin],
        NonWorkingDay: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist],
        UserManagement: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ProductSpecialist,
            Role.TeamsManager,
            Role.TeamLead,
        ],
        Users: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ProductSpecialist,
            Role.TeamsManager,
            Role.TeamLead,
        ],
        UserGroups: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ProductSpecialist,
            Role.TeamsManager
        ],
        Roles: [Role.SystemAdmin],
        EmailQueue: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist],
        Audits: [Role.SystemAdmin],
        Language: [Role.SystemAdmin],
        WizardCards: [Role.SystemAdmin],
    },
    Checklists: {
        TeamAnswerSummary: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.Monitoring,
            Role.TeamLead,
            Role.TeamsManager,
            Role.ProductSpecialist,
        ],
        AnswerSummary: [
            Role.SystemAdmin,
            Role.TeamLead,
            Role.TeamsManager,
            Role.ProductSpecialist,
            Role.Capturer,
            Role.Monitoring,
            Role.TenantAdmin,
        ],
        TeamManagement: [Role.TeamLead, Role.TeamsManager, Role.ProductSpecialist],
        QuestionSetInstanceAnswers: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.TeamsManager,
            Role.TeamLead,
            Role.Capturer,
        ],
        Reviews: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist, Role.Monitoring],
    },
    ActionTracker: {
        ActionItems: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ActionItemCapturer,
            Role.ActionTrackerAdmin,
        ],
        ActionPlans: [
            Role.SystemAdmin,
            Role.TenantAdmin,
            Role.ActionItemCapturer,
            Role.ActionTrackerAdmin,
        ],
        ActionTrackerAdmin: [Role.SystemAdmin, Role.TenantAdmin, Role.ActionTrackerAdmin],
    },
};
