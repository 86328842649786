import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Common } from "../../core/constants/translation-namespace";
import { BasePaginationDto } from "../../domain/dtos/common/base-pagination-dto";

const StyledPagination = styled(Pagination)`
    .page-item .page-link {
        color: ${(props) => props.theme.palette.primary};
    }
    .active .page-link {
        background: ${(props) => props.theme.palette.primary};
        color: ${(props) => props.theme.palette.white};
        z-index: 0;
    }
    .disabled .page-link {
        color: ${(props) => props.theme.palette.secondary};
    }
`;

const StyledDropdownButton = styled(DropdownButton)`
    .dropdown-toggle {
        background-color: ${(props) => props.theme.palette.primary};
    }
`;

const StyledDiv = styled.div`
    color: ${(props) => props.theme.palette.secondary};
    padding: 5px;
    flex: 1;
`;

const pageSizeOptions = [10, 25, 50];
const initialPaginationLinkValues = [-2, -1, 0, 1, 2, 3, 4];

const getCurrentPaginationLinks = (currentPage: number): number[] => {
    return [
        currentPage - 3,
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2,
        currentPage + 3,
    ];
};

interface PaginatorProps<TPaginationType extends BasePaginationDto> {
    totalItems: number;
    paginationDto: TPaginationType;
    setPaginationProps: (paginationDto: TPaginationType) => void;
}

const Paginator = <TPaginationType extends BasePaginationDto>({
    totalItems,
    paginationDto,
    setPaginationProps,
}: PaginatorProps<TPaginationType>): JSX.Element => {
    const currentPage = paginationDto.pageNumber;
    const pageSize = paginationDto.pageSize;
    const totalPages = Math.ceil(totalItems / pageSize);
    const currentPaginationLinks = getCurrentPaginationLinks(currentPage);

    const [paginationLinks, setPaginationLinks] = useState(currentPaginationLinks);

    useEffect(() => {
        setPaginationLinks(currentPaginationLinks);
    }, [paginationDto.pageNumber]);

    const getPageSummary = (): string => {
        const isLastPage = currentPage === totalPages;
        const firstRecord = (currentPage - 1) * pageSize + 1;
        const lastRecord =
            isLastPage && totalItems % pageSize != 0
                ? firstRecord + ((totalItems % pageSize) - 1)
                : (currentPage - 1) * pageSize + pageSize;

        return `${t("PaginationRecord")} ${firstRecord} ${t("PaginationTo")} ${lastRecord} ${t(
            "PaginationOf"
        )} ${totalItems}`;
    };

    const paginateBack = (): void => {
        if (currentPage > 1) {
            const decrementPaginationLinkValues = paginationLinks.map((page) => page - 1);

            setPaginationProps({
                ...paginationDto,
                pageNumber: currentPage - 1,
            });
            setPaginationLinks(decrementPaginationLinkValues);
        }
    };

    const paginateForward = (): void => {
        if (currentPage < totalPages) {
            const incrementPaginationLinkValues = paginationLinks.map((page) => page + 1);

            setPaginationProps({
                ...paginationDto,
                pageNumber: currentPage + 1,
            });
            setPaginationLinks(incrementPaginationLinkValues);
        }
    };

    const firstPage = (): void => {
        setPaginationProps({
            ...paginationDto,
            pageNumber: 1,
        });
        setPaginationLinks(initialPaginationLinkValues);
    };

    const lastPage = (): void => {
        const currentPages = getCurrentPaginationLinks(totalPages);

        setPaginationProps({
            ...paginationDto,
            pageNumber: totalPages,
        });
        setPaginationLinks(currentPages);
    };

    const onPageClicked = (linkIndex: number): void => {
        const currentPage = paginationLinks[linkIndex];
        const newPaginationLinks = getCurrentPaginationLinks(currentPage);

        setPaginationProps({
            ...paginationDto,
            pageNumber: currentPage,
        });
        setPaginationLinks(newPaginationLinks);
    };

    const updatePageSize = (newPageSize: number): void => {
        const currentPageLinkValue = paginationLinks[3];
        const newTotalPages = Math.ceil(totalItems / newPageSize);
        const isNewPageCountLessThanCurrentPageNumber = newTotalPages < currentPageLinkValue;

        if (isNewPageCountLessThanCurrentPageNumber) {
            setPaginationLinks(getCurrentPaginationLinks(newTotalPages));
            setPaginationProps({
                ...paginationDto,
                pageNumber: newTotalPages,
                pageSize: newPageSize,
            });
            return;
        }

        setPaginationProps({
            ...paginationDto,
            pageSize: newPageSize,
        });
    };

    const renderPaginationButtons = (): JSX.Element[] => {
        const activePaginationLinkIndex = 3;
        const paginationLinksToDisplay = [];
        const activePaginationLinkValue = paginationLinks[activePaginationLinkIndex];

        for (let paginationIndex = 0; paginationIndex <= 6; paginationIndex++) {
            const currentPageNumber = paginationLinks[paginationIndex];

            if (activePaginationLinkValue === 1 && paginationIndex < 3) {
                paginationLinksToDisplay.push(<React.Fragment key={currentPageNumber} />);
            } else if (activePaginationLinkValue === 2 && paginationIndex < 2) {
                paginationLinksToDisplay.push(<React.Fragment key={currentPageNumber} />);
            } else if (activePaginationLinkValue === 3 && paginationIndex < 1) {
                paginationLinksToDisplay.push(<React.Fragment key={currentPageNumber} />);
            } else if (activePaginationLinkValue === totalPages && paginationIndex > 3) {
                paginationLinksToDisplay.push(<React.Fragment key={currentPageNumber} />);
            } else if (activePaginationLinkValue === totalPages - 1 && paginationIndex > 4) {
                paginationLinksToDisplay.push(<React.Fragment key={currentPageNumber} />);
            } else if (activePaginationLinkValue === totalPages - 2 && paginationIndex > 5) {
                paginationLinksToDisplay.push(<React.Fragment key={currentPageNumber} />);
            } else {
                paginationLinksToDisplay.push(
                    <Pagination.Item
                        key={currentPageNumber}
                        onClick={() => onPageClicked(paginationIndex)}
                        active={paginationDto.pageNumber === currentPageNumber}
                    >
                        {currentPageNumber}
                    </Pagination.Item>
                );
            }
        }
        return paginationLinksToDisplay;
    };

    const buildTitle = (): string => `${pageSize ? pageSize : "-"} ${t("PaginationItemsPerPage")}`;
    const { t } = useTranslation("translation", { keyPrefix: Common });

    return (
        <>
            <StyledDiv>{getPageSummary()}</StyledDiv>
            <StyledPagination>
                <Pagination.First onClick={firstPage} disabled={currentPage <= 1}>
                    {t("PaginationFirst")}
                </Pagination.First>
                {currentPage > 1 && <Pagination.Prev onClick={paginateBack}> « </Pagination.Prev>}
                {renderPaginationButtons()}
                {currentPage < totalPages && (
                    <Pagination.Next onClick={paginateForward}> » </Pagination.Next>
                )}
                <Pagination.Last onClick={lastPage} disabled={currentPage >= totalPages}>
                    {t("PaginationLast")}
                </Pagination.Last>
            </StyledPagination>
            <StyledDropdownButton title={buildTitle()}>
                {pageSizeOptions.map((newPageSize, idx) => (
                    <Dropdown.Item
                        key={`pageSizeOpt${idx}`}
                        onClick={() => updatePageSize(newPageSize)}
                    >
                        {newPageSize}
                    </Dropdown.Item>
                ))}
            </StyledDropdownButton>
        </>
    );
};

export default Paginator;
