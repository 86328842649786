import { MaxIntValue } from "../constants/numerical";

export const NavbarTitles = {
    Checklists: "Checklists",
    Admin: "Admin",
    ActionTracker: "ActionTracker",
    MyActionCalendar: "MyActionCalendar",
    GoToRMP: "GoToRMP",
    Help: "Help",
    Support: "Support",
};

export const DrawerTitles = {
    Dashboard: "Dashboard",
    Profile: "Profile",
    TeamAnswerSummary: "TeamAnswerSummary",
    AnswerSummary: "AnswerSummary",
    AnswerCapture: "AnswerCapture",
    CompletedAnswers: "CompletedAnswers",
    TriggerAdhocs: "TriggerAdhocs",
    PendingAdhocs: "PendingAdhocs",
    Reviews: "Reviews",
    ReviewsHistory: "ReviewsHistory",
    Library: "Library",
    Hierarchy: "Hierarchy",
    NonWorkingDay: "NonWorkingDay",
    UserManagement: "UserManagement",
    EmailQueue: "EmailQueue",
    MyActionCalendar: "MyActionCalendar",
    ActionPlans: "ActionPlans",
    ActionItems: "ActionItems",
    MyActionSummary: "MyActionSummary",
    MyActionItems: "MyActionItems",
    FinalizedActionItems: "FinalizedActionItems",
    ActionItemDynamicFields: "ActionItemDynamicFields",
    ActionItemDynamicFieldTexts: "ActionItemDynamicFieldTexts",
    NotificationTriggers: "NotificationTriggers",
    Audits: "Audits",
    WizardCards: "WizardCards",
};

export const AccordionTitles = {
    Checklists: "Checklists",
    QuestionSets: "QuestionSets",
    Questions: "Questions",
    QuestionCategories: "QuestionCategories",
    AnswerTypes: "AnswerTypes",
    AnswerTypePredefinedAnswers: "PredefinedAnswers",
    AnswerTypePredefinedAnswerTexts: "PredefinedAnswerText",
    ReferentialLinkTypes: "ReferentialLinkTypes",
    Import: "Import",
    VisualTree: "VisualTree",
    Search: "Search",
    VisualStructure: "VisualStructure",
    VisualStructureValues: "VisualStructureValues",
    Admin: "Admin",
    HierarchyImport: "HierarchyImport",
    Users: "Users",
    UserGroups: "UserGroups",
    AuditLog: "AuditLog",
    ImageLibrary: "ImageLibrary",
    Wizards: "Wizards",
};

export const actionItemActions = {
    Extend: "Extend",
    PlaceOnHold: "Place On Hold",
    CancelItem: "Cancel Item",
    Edit: "Edit",
    Clone: "Clone",
    Reactivate: "Reactivate",
    Complete: "Complete",
    Reopen: "Reopen",
};

export const DataTableActions = {
    View: "view",
    Edit: "edit",
    Delete: "delete",
};

export const DataTableColumnTypes = {
    Sort: "Sort",
    SortAsc: "SortAsc",
    SortDesc: "SortDesc",
    Check: "Check",
    CheckAll: "CheckAll",
    Text: "Text",
    LinkAndRibbon: "LinkAndRibbon",
    Link: "Link",
    TimeInput: "TimeInput",
    DateTimeInput: "DateTimeInput",
    PeriodInput: "PeriodInput",
    DisplayNestedTableButton: "DisplayNestedTableButton",
    IconLink: "IconLink",
    DisplayAttachedFilesButton: "DisplayAttachedFilesButton",
};

export const ConfirmationRowTypes = {
    Check: "Check",
    Text: "Text",
};

export const AggregationTypes = {
    Default: 1,
};

export enum NotificationStatuses {
    Unseen = 1,
    Dismissed = 2,
    DontShowAgain = 3,
    Finished = 4,
}

export enum HierarchyAssignableFlag {
    None = 0,
    NonWorkingDaySet = 1,
    Question = 2,
    User = 4,
    Checklist = 8,
    ChecklistInstances = 16,
    ActionItems = 32,
    ActionItemTypes = 64,
    TrainableHierarchy = 128,
    OrganisationalNodesForActionItems = 256,
    GeographyNodesForActionItems = 512,
    All = MaxIntValue,
}
export enum AnswerThresholdOperatorEnum {
    LessThan = 1,
    LessThanOrEqual = 2,
    GreaterThan = 3,
    GreaterThanOrEqual = 4,
}

export enum AnswerThresholdIndicatorEnum {
    Red = 1,
    Amber = 2,
}
