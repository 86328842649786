import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    associateUserToNode,
    dissociateUser,
    filterAssignedUsersToNode,
} from "../../../data/services/hierarchy/nodes-service";
import { filterUsers } from "../../../data/services/users/users-service";
import {
    AssignedUsersToNodeDto,
    createAssignedUsersToNodeDto,
} from "../../dtos/hierarchy/assigned-users-to-node-dto";
import {
    BaseUserDto,
    toBaseUserDtosFromPaginatedUserResponse,
} from "../../dtos/users/base-user-dto";
import { createUserNodeAssociationDissociationRequest } from "../../requests/hierarchy/user-node-association-dissociation-request";
import { createFilterUsersSearchRequest } from "../../requests/users/filter-users-request";
import { Response } from "../../responses/common/response-response";
import { UserNodeAssociationParameters } from "./dissociate-user-viewmodel";

export const useFilterAssignedUsersToNode = (
    nodeId: number | null
): UseQueryResult<AssignedUsersToNodeDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAssignedUsersToNode", nodeId],
        () => filterAssignedUsersToNode(url.baseUrl, nodeId!),
        {
            enabled: nodeId !== null,
            select: createAssignedUsersToNodeDto,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterUsers = (
    pageNumber: number,
    pageSize: number,
    searchText: string | null
): UseQueryResult<BaseUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsers", pageNumber, pageSize, searchText],
        () =>
            filterUsers(
                url.baseUrl,
                createFilterUsersSearchRequest(searchText!, pageNumber, pageSize, false)
            ),
        {
            enabled: searchText != null && searchText.trim().length > 0,
            select: toBaseUserDtosFromPaginatedUserResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useAssociateUserToNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    UserNodeAssociationParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: UserNodeAssociationParameters) => {
        const { nodeId, userId } = mutationKey;

        return associateUserToNode(
            url.baseUrl,
            createUserNodeAssociationDissociationRequest(nodeId, userId)
        );
    });
};

export const useDissociateUser = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    UserNodeAssociationParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: UserNodeAssociationParameters) => {
        const { nodeId, userId } = mutationKey;

        return dissociateUser(
            url.baseUrl,
            createUserNodeAssociationDissociationRequest(nodeId, userId)
        );
    });
};
