export interface BasePaginationDto {
    pageNumber: number;
    pageSize: number;
}

export const createBasePaginationDto = (
    pageNumber: number,
    pageSize: number
): BasePaginationDto => ({
    pageNumber: pageNumber,
    pageSize: pageSize,
});

export const defaultBasePaginationDto = createBasePaginationDto(1, 10);
