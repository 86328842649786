import { Col, Row } from "react-bootstrap";
import { Users } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { useTranslation } from "react-i18next";

export const UserGroupsFilter: React.FC<{
    name: string | null;
    changeGroupName: (groupName: string) => void;
    email: string | null;
    changeGroupEmailAddress: (emailAddress: string) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    name,
    changeGroupName,
    email,
    changeGroupEmailAddress,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: Users });

    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("GroupName")}
                            name={"name"}
                            type={"text"}
                            onChangeEventHandler={changeGroupName}
                            value={name ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterInput
                            title={t("GroupEmailAddress")}
                            name={"email"}
                            type={"text"}
                            onChangeEventHandler={changeGroupEmailAddress}
                            value={email ?? ""}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
