import React, { ReactElement, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ChecklistAnswers, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { createNavigateSearchParameter } from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    createErrorToastProps,
    createPartialSuccessToastProps,
    createSuccessNavigateToastProps,
    createSuccessToastProps,
    createToastNavigationProps,
    useToast,
} from "../../../core/store/toast-context";
import {
    ContentContainer,
    EndAlignedDiv,
    NoDataStateDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { getNullableString, toBoolean } from "../../../core/utilities/string-helper";
import queryClient from "../../../data/query-client";
import FilterUserQuestionsDto from "../../../domain/dtos/answer-capture/filter-user-questions-dto";
import QuestionCaptureSetDto from "../../../domain/dtos/answer-capture/question-capture-set-dto";
import {
    BasePaginationDto,
    createBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import DataStatus from "../../../domain/enums/common/DataStatus";
import InstanceStatus from "../../../domain/enums/questions/instance-status";
import QuestionCaptureResultsResponse from "../../../domain/responses/answer-capture/question-capture-results-response";
import { Response } from "../../../domain/responses/common/response-response";
import { useAnswerCapture } from "../../../domain/stores/answer-capture-context";
import {
    useCreateQuestionSetInstanceAnswerReview,
    useFilterUserQuestions,
    useGenerateQuestionAnswerActionItem,
    useGetAnswerCaptureFilterData,
    useSaveAnswers,
    useSubmitAnswers,
} from "../../../domain/viewmodels/answer-capture/answer-capture-viewmodel";
import Paginator from "../../atoms/Paginator";
import { SbButton } from "../../atoms/SbButton";
import QuestionCaptureSet from "../../organisms/answer-capture/question-capture-set";
import { AnswerCaptureFilter } from "../../organisms/filters/AnswerCaptureFilter";
import SbModal, { ModalSize } from "../../molecules/SbModal";

const StyledChecklistsHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${(props) => props.theme.padding.md};
    color: ${(props) => props.theme.palette.primary};
    border-bottom: 1px solid ${(props) => props.theme.palette.dividerColour};
`;

const StyledChecklistsFooter = styled(EndAlignedDiv)`
    padding-top: ${(props) => props.theme.padding.md};
    color: ${(props) => props.theme.palette.primary};
    border-top: 1px solid ${(props) => props.theme.palette.dividerColour};
`;

interface StyledSaveAnswersDivProps {
    readonly $isProduction: boolean;
}

const StyledSaveAnswersDiv = styled(ContentContainer)<StyledSaveAnswersDivProps>`
    position: sticky;
    top: ${(props) =>
        props.$isProduction
            ? props.theme.customPadding.saveAnswersButtonProd
            : props.theme.customPadding.saveAnswersButtonNonProd};
    z-index: 10;
    display: flex;
    justify-content: center;
    padding: ${(props) => props.theme.padding.md} 0px;
    margin-bottom: ${(props) => props.theme.padding.md};
`;

const emptyFilterUserQuestionsDto = FilterUserQuestionsDto.emptyFilterUserQuestionsDto;
const defaultPaginationDto = createBasePaginationDto(1, 10);

const AnswerCaptureContainer: React.FC = () => {
    const environment = process.env.REACT_APP_ENV;
    const isProductionEnvironment = environment?.toUpperCase() === "PROD";

    const [urlSearchParams] = useSearchParams();

    const nodeIdSearchParamString = getNullableString(urlSearchParams.get("nodeId"));
    const instanceStatusSearchParamString = getNullableString(
        urlSearchParams.get("instanceStatus")
    );
    const isTrainingSearchParamString = getNullableString(urlSearchParams.get("isTraining"));

    const searchParamsPassed =
        nodeIdSearchParamString || instanceStatusSearchParamString || isTrainingSearchParamString;

    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultPaginationDto);
    const [paginationStagingDto, setPaginationStagingDto] = useState<BasePaginationDto>(defaultPaginationDto);
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [areAnswersSaved, setAreAnswersSaved] = useState(false);

    const [filterUserQuestionsDto, setFilterUserQuestionsDto] =
        useState<FilterUserQuestionsDto | null>(
            searchParamsPassed ? null : emptyFilterUserQuestionsDto
        );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmSubmitClicked, setConfirmSubmitClicked] = useState(false);
    const [savedQuestionAnswersPresent, setSavedQuestionAnswersPresent] = useState<boolean | null>(
        null
    );
    const [loadedSavedQuestionAnswers, setLoadedSavedQuestionAnswers] = useState(false);
    const [showAnswersSubmitSuccessToast, setShowAnswersSubmitSuccessToast] = useState(false);
    const [showAnswersSavedSuccessToast, setShowAnswersSavedSuccessToast] = useState(false);
    const [showConfirmAnswerSummaryNextPageModal, setShowConfirmAnswerSummaryNextPageModal] = useState(false);

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const answerCapture = useAnswerCapture();

    const getAnswerCaptureFilterData = useGetAnswerCaptureFilterData();
    const filterUserQuestions = useFilterUserQuestions(paginationDto, filterUserQuestionsDto);
    const submitAnswers = useSubmitAnswers();
    const saveAnswers = useSaveAnswers();
    const createQuestionSetInstanceAnswerReview = useCreateQuestionSetInstanceAnswerReview();
    const generateQuestionAnswerActionItem = useGenerateQuestionAnswerActionItem();

    const userNodesResponseData = getAnswerCaptureFilterData[0].data;
    const userChecklistsResponseData = getAnswerCaptureFilterData[1].data;
    const filterUserQuestionsDetails = filterUserQuestions.data?.paginatedQuestionCaptureSetDto;
    const filterUserQuestionsSavedData = filterUserQuestions.data?.savedQuestionCaptureDtos;
    const filterUserNestedQuestionsSavedData =
        filterUserQuestions.data?.savedNestedQuestionCaptureDtos;

    useLoader(
        areQueriesLoading([...getAnswerCaptureFilterData, filterUserQuestions]) ||
            areMutationsLoading([
                submitAnswers,
                saveAnswers,
                createQuestionSetInstanceAnswerReview,
                generateQuestionAnswerActionItem,
            ]) ||
            !loadedSavedQuestionAnswers,
        AnswerCaptureContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.AnswerCapture);

        return () => answerCapture.clearState();
    }, []);

    // TODO: Improvement - move Review Comment and Action Item Trigger out of this container and Store, and put into their own Containers
    useEffect(() => {
        const creatingQuestionReviewComment = answerCapture.creatingQuestionReviewComment;
        if (creatingQuestionReviewComment) {
            createQuestionSetInstanceAnswerReview.mutate(creatingQuestionReviewComment, {
                onSuccess: onCreatingQuestionReviewCommentSuccess,
                onError: errorResponseToDisplayHandler,
            });
        }
    }, [answerCapture.creatingQuestionReviewComment]);

    useEffect(() => {
        const triggeringQuestionActionItem = answerCapture.triggeringQuestionActionItem;
        if (triggeringQuestionActionItem) {
            generateQuestionAnswerActionItem.mutate(triggeringQuestionActionItem, {
                onSuccess: onTriggeringQuestionActionItemSuccess,
                onError: errorResponseToDisplayHandler,
            });
        }
    }, [answerCapture.triggeringQuestionActionItem]);

    useEffect(() => {
        if (isQuerySuccessful(filterUserQuestions)) {
            setSavedQuestionAnswersPresent(
                filterUserQuestionsSavedData!.length > 0 ||
                    filterUserNestedQuestionsSavedData!.length > 0
            );

            answerCapture.updateQuestionAnswerDtosFromSavedAnswers(
                filterUserQuestionsSavedData!,
                filterUserNestedQuestionsSavedData!
            );
        }

        if (showAnswersSubmitSuccessToast) {
            toast.addToast(createSuccessToastProps([t("AnswersSubmittedSuccessfully")]));
            setShowAnswersSubmitSuccessToast(false);
        }

        if (showAnswersSavedSuccessToast) {
            toast.addToast(createSuccessToastProps([t("AnswersSavedSuccessfully")]));
            setShowAnswersSavedSuccessToast(false);
        }
    }, [filterUserQuestions.data]);

    useEffect(() => {
        if (filterUserQuestions.isError) {
            setSavedQuestionAnswersPresent(false);
        }
    }, [filterUserQuestions.status]);

    useEffect(() => {
        if (!loadedSavedQuestionAnswers) {
            const savedQuestionAnswersLoaded =
                savedQuestionAnswersPresent === true && answerCapture.hasAnsweredQuestions;
            setLoadedSavedQuestionAnswers(
                savedQuestionAnswersLoaded || savedQuestionAnswersPresent === false
            );
        }
    }, [
        answerCapture.getQuestionAnswerValues(),
        savedQuestionAnswersPresent,
        loadedSavedQuestionAnswers,
    ]);

    const buildNodeIdSearchParam = (): number | null =>
        nodeIdSearchParamString ? Number(nodeIdSearchParamString) : null;

    const buildInstanceStatusSearchParam = (): InstanceStatus | null =>
        instanceStatusSearchParamString
            ? (Number(instanceStatusSearchParamString) as InstanceStatus)
            : null;

    const buildDataStatusSearchParam = (): DataStatus | null =>
        isTrainingSearchParamString
            ? toBoolean(isTrainingSearchParamString)
                ? DataStatus.Training
                : DataStatus.Live
            : null;

    const clearSavedAnswerTrackingStates = (): void => {
        setSavedQuestionAnswersPresent(null);
        setLoadedSavedQuestionAnswers(false);
    };

    const clearSubmitButtonStates = (): void => {
        setIsSubmitting(false);
        setConfirmSubmitClicked(false);
    };

    const clearStatesForDataRefetch = (): void => {
        clearSavedAnswerTrackingStates();
        answerCapture.clearState();
    };

    const onCreatingQuestionReviewCommentSuccess = async (): Promise<void> => {
        answerCapture.updateCreatedQuestionReviewCommentSuccessfully(true);

        toast.addToast(createSuccessToastProps(["New review successfully generated"]));
    };

    const onTriggeringQuestionActionItemSuccess = async (
        response: Response<number | null>
    ): Promise<void> => {
        answerCapture.updateTriggeredQuestionActionItemSuccessfully(true);

        buildNavigableToastForGeneratedActionItem(response.data);
    };

    const getQuestionsToRender = (): QuestionCaptureSetDto[] => {
        if (isSubmitting) {
            return buildAndSetAnsweredQuestions();
        }

        return filterUserQuestionsDetails!.results;
    };

    const onActionClicked = (filterUserQuestionsDto: FilterUserQuestionsDto): void => {
        clearStatesForDataRefetch();
        setFilterUserQuestionsDto(filterUserQuestionsDto);
        setPaginationDto(defaultPaginationDto);
    };

    const buildAndSetAnsweredQuestions = (): QuestionCaptureSetDto[] => {
        const questionCaptureSetDtos = filterUserQuestionsDetails!.results.map((x) => ({ ...x }));

        const answeringQuestionSetInstanceAnswerIds = answerCapture
            .getQuestionAnswerValues()
            .filter((x) => x.parentQuestionId === null)
            .map((x) => x.questionSetInstanceAnswerId);

        questionCaptureSetDtos.forEach(
            (x) =>
                (x.questionCaptureDtos = x.questionCaptureDtos.filter((x) =>
                    answeringQuestionSetInstanceAnswerIds.includes(x.questionSetInstanceAnswerId)
                ))
        );

        return questionCaptureSetDtos.filter((x) => x.questionCaptureDtos.length > 0);
    };

    const onSubmitButtonClicked = (): void => {
        if (isSubmitting) {
            setConfirmSubmitClicked(true);
        } else {
            setIsSubmitting(true);
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        if (confirmSubmitClicked) {
            event.preventDefault();

            submitAnswers.mutate(answerCapture.getQuestionAnswerValues(), {
                onSuccess: onAnswerSubmitSuccess,
                onError: errorResponseToDisplayHandler,
            });
        }
    };

    const onAnswerSubmitSuccess = async (
        response: Response<QuestionCaptureResultsResponse>
    ): Promise<void> => {
        const failedQuestionCaptureResultResponses =
            response.data.failedQuestionCaptureResultResponses;

        if (failedQuestionCaptureResultResponses.length > 0) {
            toast.addToast(
                createPartialSuccessToastProps([t("AnswersCouldNotBeSubmittedPleaseReSubmit")])
            );

            failedQuestionCaptureResultResponses.forEach((x) =>
                toast.addToast(createErrorToastProps(x.errors))
            );
        } else {
            setShowAnswersSubmitSuccessToast(true);
        }

        response.data.successfulQuestionCaptureResults.forEach((x) => {
            buildNavigableToastForGeneratedActionItem(x.actionItemInstanceId);
        });

        clearSubmitButtonStates();
        clearStatesForDataRefetch();
        queryClient.refetchQueries(["filterUserQuestions", filterUserQuestionsDto, paginationDto]);
    };

    const onSaveButtonClicked = (): void => {
        saveAnswers.mutate(answerCapture.getQuestionAnswerValues(), {
            onSuccess: async (response: Response<QuestionCaptureResultsResponse>) => {
                const failedQuestionCaptureResultResponses =
                    response.data.failedQuestionCaptureResultResponses;

                if (failedQuestionCaptureResultResponses.length > 0) {
                    toast.addToast(
                        createPartialSuccessToastProps(["Some answers were saved successfully"])
                    );

                    failedQuestionCaptureResultResponses.forEach((x) =>
                        toast.addToast(createErrorToastProps(x.errors))
                    );
                } else {
                    setAreAnswersSaved(true);
                    setShowAnswersSavedSuccessToast(true);
                }

                clearSavedAnswerTrackingStates();
                answerCapture.clearState();
                queryClient.refetchQueries([
                    "filterUserQuestions",
                    filterUserQuestionsDto,
                    paginationDto,
                ]);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const onPaginationClicked = (paginationDto: BasePaginationDto): void => {  
        if (isFormTouched && !areAnswersSaved){
            setPaginationStagingDto(paginationDto);
            setShowConfirmAnswerSummaryNextPageModal(true);
        }
        else {
            clearStatesForDataRefetch();
            setPaginationDto(paginationDto);
            setIsFormTouched(false);
            setAreAnswersSaved(false);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }      
    };

    const handleConfirmNavigation = (): void => {
        clearStatesForDataRefetch();
        setPaginationDto(paginationStagingDto);
        setIsFormTouched(false);
        setAreAnswersSaved(false);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };


    const handleFocus = (event: React.FocusEvent<HTMLFormElement>) => {
        if (event.target.closest('[data-role="paginator"]')) {
            return;
        }
        setIsFormTouched(true);
    };


    const buildNavigableToastForGeneratedActionItem = (
        actionItemInstanceId: number | null
    ): void => {
        if (actionItemInstanceId) {
            const navigationProps = createToastNavigationProps(
                `${getPath(DrawerTitles.MyActionItems)}`,
                [
                    createNavigateSearchParameter(
                        "actionItemInstanceId",
                        actionItemInstanceId.toString()
                    ),
                ]
            );

            toast.addToast(
                createSuccessNavigateToastProps(
                    [
                        "Action Item Automatically Generated",
                        "You can go to the generated action item by clicking this link",
                    ],
                    navigationProps
                )
            );
        }
    };

    const buildSubmitButtons = (): ReactElement<typeof Button> => (
        <>
            {isSubmitting && (
                <SbButton
                    onClick={clearSubmitButtonStates}
                    type={"button"}
                    variant={"secondary"}
                    label={"Cancel"}
                />
            )}
            <SbButton
                onClick={onSubmitButtonClicked}
                type={confirmSubmitClicked ? "submit" : "button"}
                variant={"primary"}
                label={isSubmitting ? t("ConfirmSubmit") : t("Submit", { keyPrefix: Common })}
                disabled={!answerCapture.hasAnsweredQuestions || isMutationLoading(submitAnswers)}
            />
        </>
    );

    const buildResultPaginator = (): ReactElement<HTMLDivElement> => {
        if (isSubmitting) {
            return <></>;
        }

        if (filterUserQuestionsDetails!.recordCount === 0) {
            return <NoDataStateDiv>{"No active checklists available"}</NoDataStateDiv>;
        }

        return (
            <EndAlignedDiv>
                <Paginator
                    totalItems={filterUserQuestionsDetails!.recordCount}
                    paginationDto={paginationDto}
                    setPaginationProps={onPaginationClicked}
                />
            </EndAlignedDiv>
        );
    };

    return (
        <>
            {isQuerySuccessful(filterUserQuestions) && (
                <StyledSaveAnswersDiv $isProduction={isProductionEnvironment}>
                    <SbButton
                        variant={"primary"}
                        type={"button"}
                        label={t("SaveAnswers", { keyPrefix: Common })}
                        onClick={onSaveButtonClicked}
                    />
                </StyledSaveAnswersDiv>
            )}

            <PageHeading>{t("AnswerCapture")}</PageHeading>
            <PageSubHeading>{t("CaptureYourAnswersHere")}</PageSubHeading>
            <SectionVerticalSpace id={"topOfPageContent"} />

            {areQueriesSuccessful(getAnswerCaptureFilterData) && (
                <>
                    <AnswerCaptureFilter
                        nodesDropdownDto={userNodesResponseData!}
                        checklistsDropdownDto={userChecklistsResponseData!}
                        nodeIdSearchParam={buildNodeIdSearchParam()}
                        instanceStatusSearchParam={buildInstanceStatusSearchParam()}
                        dataStatusSearchParam={buildDataStatusSearchParam()}
                        onActionClicked={onActionClicked}
                    />
                    <SectionVerticalSpace />
                </>
            )}

            {isQuerySuccessful(filterUserQuestions) && loadedSavedQuestionAnswers && (
                <ContentContainer>
                    <Form onSubmit={onSubmit} onFocus={handleFocus}>
                        <StyledChecklistsHeading>
                            {t("YourActiveChecklists")}
                            <EndAlignedDiv>{buildSubmitButtons()}</EndAlignedDiv>
                        </StyledChecklistsHeading>

                        {getQuestionsToRender().map((x, index) => (
                            <QuestionCaptureSet
                                key={index}
                                questionCaptureSetDetailsDto={x.questionCaptureSetDetailsDto}
                                questionCaptureDtos={x.questionCaptureDtos}
                            />
                        ))}

                        <div data-role="paginator">
                            {buildResultPaginator()}
                        </div>

                        <StyledChecklistsFooter>{buildSubmitButtons()}</StyledChecklistsFooter>
                    </Form>
                </ContentContainer>
            )}
            <SbModal
                title={t("PleaseConfirm", { keyPrefix: Common })}
                subtitle={t("MakeSureAnswerCapture", { keyPrefix: Common })}
                body={t("AreYouSureNavigation", { keyPrefix: Common })}
                primaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                secondaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                onSecondaryButtonClicked={handleConfirmNavigation}
                isVisible={showConfirmAnswerSummaryNextPageModal}
                updateIsVisible={setShowConfirmAnswerSummaryNextPageModal}
                size={ModalSize.Medium}
            />
        </>
    );
};

export default AnswerCaptureContainer;
