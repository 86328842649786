import { AssociateDissociateUserGroupNodeDto } from "../../dtos/user-groups/associate-dissociate-user-group-node-dto";

export interface UserGroupNodeAssociationDissociationRequest {
    nodeId: number;
    userGroupId: number;
}

export const createUserGroupNodeAssociationDissociationRequest = (
    dto: AssociateDissociateUserGroupNodeDto
): UserGroupNodeAssociationDissociationRequest => ({
    nodeId: dto.nodeId,
    userGroupId: dto.userGroupId,
});

export const buildUserGroupNodeAssociationDissociationSearchParameters = (
    request: UserGroupNodeAssociationDissociationRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    searchParams.set("nodeId", request.nodeId.toString());
    searchParams.set("userGroupId", request.userGroupId.toString());

    return searchParams;
};
