import { MenuRoleGroup } from "../../domain/enums/MenuRoles";
import { Role } from "../../domain/enums/Roles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "./enums";

interface MenuItem {
    title: string;
    isActive: boolean;
    requiredRoles?: Role[];
}

interface AccordionChildMenuItem extends MenuItem {
    isNewSection?: boolean;
}

interface SideBarMenuItem extends MenuItem {
    tenantOnly?: boolean;
    adminOnly?: boolean;
    accordionItems?: AccordionChildMenuItem[];
}

export interface NavBarMenuItem extends MenuItem {
    tenantOnly?: boolean;
    drawerItems: SideBarMenuItem[];
}

export const initialMenuItems: NavBarMenuItem[] = [
    {
        title: NavbarTitles.Checklists,
        isActive: false,
        tenantOnly: true,
        drawerItems: [
            {
                title: DrawerTitles.TeamAnswerSummary,
                isActive: false,
                requiredRoles: MenuRoleGroup.Checklists.TeamAnswerSummary,
            },
            {
                title: DrawerTitles.AnswerSummary,
                isActive: false,
                requiredRoles: MenuRoleGroup.Checklists.AnswerSummary,
            },
            {
                title: DrawerTitles.AnswerCapture,
                isActive: false,
                requiredRoles: MenuRoleGroup.Checklists.QuestionSetInstanceAnswers,
            },
            {
                title: DrawerTitles.CompletedAnswers,
                isActive: false,
                requiredRoles: MenuRoleGroup.Checklists.QuestionSetInstanceAnswers,
            },
            {
                title: DrawerTitles.TriggerAdhocs,
                isActive: false,
                requiredRoles: MenuRoleGroup.Checklists.QuestionSetInstanceAnswers,
            },
            {
                title: DrawerTitles.PendingAdhocs,
                isActive: false,
                requiredRoles: MenuRoleGroup.Checklists.QuestionSetInstanceAnswers,
            },
            {
                title: DrawerTitles.Reviews,
                isActive: false,
                requiredRoles: MenuRoleGroup.Checklists.Reviews,
            },
            {
                title: DrawerTitles.ReviewsHistory,
                isActive: false,
                requiredRoles: MenuRoleGroup.Checklists.Reviews,
            },
        ],
    },
    {
        title: NavbarTitles.Admin,
        isActive: false,
        drawerItems: [
            {
                title: DrawerTitles.Dashboard,
                isActive: false,
                requiredRoles: MenuRoleGroup.Admin.Dashboard,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.Library,
                isActive: false,
                requiredRoles: MenuRoleGroup.Admin.Library,
                tenantOnly: true,
                accordionItems: [
                    {
                        title: AccordionTitles.Checklists,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.Checklists,
                    },
                    {
                        title: AccordionTitles.QuestionSets,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.QuestionSets,
                    },
                    {
                        title: AccordionTitles.Questions,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.Questions,
                    },
                    {
                        title: AccordionTitles.QuestionCategories,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.QuestionCategories,
                        isNewSection: true,
                    },
                    {
                        title: AccordionTitles.AnswerTypes,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.AnswerTypes,
                    },
                    {
                        title: AccordionTitles.ReferentialLinkTypes,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.ReferentialLinkTypes,
                    },
                    {
                        title: AccordionTitles.Import,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.ChecklistImport,
                    },
                ],
            },
            {
                title: DrawerTitles.Hierarchy,
                isActive: false,
                requiredRoles: MenuRoleGroup.Admin.Hierarchy,
                tenantOnly: true,
                accordionItems: [
                    {
                        title: AccordionTitles.VisualTree,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.VisualTree,
                    },
                    {
                        title: AccordionTitles.Search,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.Search,
                    },
                    {
                        title: AccordionTitles.VisualStructure,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.VisualStructure,
                    },
                    {
                        title: AccordionTitles.Admin,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.HierarchyAdmin,
                    },
                    {
                        title: AccordionTitles.HierarchyImport,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.HierarchyImport,
                    },
                ],
            },
            {
                title: DrawerTitles.NonWorkingDay,
                isActive: false,
                requiredRoles: MenuRoleGroup.Admin.NonWorkingDay,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.UserManagement,
                isActive: false,
                requiredRoles: MenuRoleGroup.Admin.UserManagement,
                accordionItems: [
                    {
                        title: AccordionTitles.Users,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.Users,
                    },
                    {
                        title: AccordionTitles.UserGroups,
                        isActive: false,
                        requiredRoles: MenuRoleGroup.Admin.UserGroups,
                    },
                ],
            },
            {
                title: DrawerTitles.WizardCards,
                isActive: false,
                adminOnly: true,
                accordionItems: [
                    {
                        title: AccordionTitles.Wizards,
                        isActive: false,
                    },
                    {
                        title: AccordionTitles.ImageLibrary,
                        isActive: false,
                    },
                ],
            },
            {
                title: DrawerTitles.EmailQueue,
                isActive: false,
                requiredRoles: MenuRoleGroup.Admin.EmailQueue,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.Audits,
                isActive: false,
                requiredRoles: MenuRoleGroup.Admin.Audits,
            },
        ],
    },
    {
        title: NavbarTitles.ActionTracker,
        isActive: false,
        tenantOnly: true,
        drawerItems: [
            {
                title: DrawerTitles.MyActionCalendar,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionItems,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.ActionPlans,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionPlans,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.ActionItems,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionTrackerAdmin,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.MyActionSummary,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionItems,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.MyActionItems,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionItems,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.FinalizedActionItems,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionItems,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.ActionItemDynamicFields,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionTrackerAdmin,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.NotificationTriggers,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionTrackerAdmin,
                tenantOnly: true,
            },
        ],
    },
    {
        title: NavbarTitles.MyActionCalendar,
        isActive: false,
        tenantOnly: true,
        drawerItems: [
            {
                title: DrawerTitles.MyActionCalendar,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionItems,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.ActionPlans,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionPlans,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.ActionItems,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionPlans,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.MyActionSummary,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionItems,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.MyActionItems,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionItems,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.FinalizedActionItems,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionItems,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.ActionItemDynamicFields,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionTrackerAdmin,
                tenantOnly: true,
            },
            {
                title: DrawerTitles.NotificationTriggers,
                isActive: false,
                requiredRoles: MenuRoleGroup.ActionTracker.ActionTrackerAdmin,
                tenantOnly: true,
            },
        ],
    },
];
