export enum Role {
    SystemAdmin,
    TenantAdmin,
    ProductSpecialist,
    TeamsManager,
    TeamLead,
    Capturer,
    ActionTrackerAdmin,
    ActionItemCapturer,
    Monitoring,
    ActionTrackerAdvance,
    MonitoringEvaluator,
    MonitoringAdmin,
}

export const UserRoleGroup = {
    SuspensionRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.ProductSpecialist,
        Role.TeamsManager,
    ],
    SystemAdminRoles: [Role.SystemAdmin],
    AdminRoles: [Role.SystemAdmin, Role.TenantAdmin],
    WriteRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.TeamsManager,
        Role.TeamLead,
        Role.ProductSpecialist,
    ],
    AssignRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.ProductSpecialist,
        Role.TeamsManager,
        Role.TeamLead,
    ],
    UserGroupRoles:[
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.ProductSpecialist,
        Role.TeamsManager,
    ]
};

export const NodeRoleGroup = {
    WriteRoles: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist],
    SuspendRoles: [Role.SystemAdmin, Role.TenantAdmin],
    PromoteDemoteRoles: [Role.SystemAdmin, Role.TenantAdmin],
    DeleteRoles: [Role.SystemAdmin, Role.TenantAdmin],
    AdminRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const NodeTypeRoleGroup = {
    WriteRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const NodeTypeValueRoleGroup = {
    WriteRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const HierarchyTypeRoleGroup = {
    WriteRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const NonWorkingDayRoleGroup = {
    WriteRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const QuestionSetInstanceRoleGroup = {
    DeleteRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.TeamsManager,
        Role.Capturer,
        Role.ProductSpecialist,
    ],
};

export const AnswerTypeRoleGroup = {
    WriteRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const ChecklistAssignmentRoleGroup = {
    PromoteRoles: [Role.SystemAdmin, Role.TenantAdmin],
    CancelAndRescheduleRoles: [Role.SystemAdmin],
};

export const ChecklistRoleGroup = {
    WriteRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.ProductSpecialist,
        Role.TeamsManager,
        Role.TeamLead,
    ],
    PromoteRoles: [Role.SystemAdmin, Role.TenantAdmin, Role.ProductSpecialist],
    EditAssignmentRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const QuestionCategoryRoleGroup = {
    WriteRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const QuestionRoleGroup = {
    WriteRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.ProductSpecialist,
        Role.TeamsManager,
        Role.TeamLead,
    ],
    EditAssociateRoles: [Role.TenantAdmin, Role.ProductSpecialist],
};

export const QuestionSetInstanceReviewRoleGroup = {
    DeleteRoles: [
        Role.Monitoring,
        Role.MonitoringEvaluator,
        Role.ProductSpecialist,
        Role.TenantAdmin,
    ],
    UnpublishMonitoringRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.MonitoringAdmin,
        Role.MonitoringEvaluator,
    ],
};

export const QuestionSetRoleGroup = {
    WriteRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.ProductSpecialist,
        Role.TeamsManager,
        Role.TeamLead,
    ],
};

export const ActionItemRoleGroup = {
    WriteRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.ActionItemCapturer,
        Role.ActionTrackerAdmin,
    ],
    ReadRoles: [
        Role.SystemAdmin,
        Role.TenantAdmin,
        Role.ActionItemCapturer,
        Role.ActionTrackerAdmin,
    ],
    EditActionItemNameRoles: [Role.SystemAdmin, Role.ActionTrackerAdmin],
};

export const NotificationTriggerRoleGroup = {
    WriteRoles: [Role.SystemAdmin, Role.TenantAdmin],
};

export const MonitoringRoles = {
    ElevatedMonitoringRoles: [Role.SystemAdmin, Role.TenantAdmin, Role.MonitoringAdmin],
};

export const transformToRoleEnums = (roles: string[]): Role[] => roles.map((x) => (Role as any)[x]);

export const hasRoleTypeInGroup = (activeRoles: Role[], roleGroup: Role[]): boolean =>
    activeRoles.some((x) => !roleGroup.length || roleGroup.includes(x));
