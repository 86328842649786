import { AssociateDissociateUserNodeDto } from "../../dtos/users/associate-dissociate-user-node-dto";

export class AssociateDissociateUserNodeRequest {
    nodeId: number;
    userId: number;

    public constructor(dto: AssociateDissociateUserNodeDto) {
        this.nodeId = dto.nodeId;
        this.userId = dto.userId;
    }
}
