import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterUserGroups } from "../../../data/services/users/user-groups-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterUserGroupsDto } from "../../dtos/users/filter-user-groups-dto";
import { UserGroupDto } from "../../dtos/users/user-group-dto";
import { createFilterUserGroupsRequestFromDto } from "../../requests/users/filter-user-groups-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { UserGroupResponse } from "../../responses/users/user-group-response";

export const useFilterUserGroups = (
    filterUserGroupsDto: FilterUserGroupsDto
): UseQueryResult<PaginatedTableDto<UserGroupDto>, HTTPError> => {
    const auth = useAuth();
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUserGroups", filterUserGroupsDto],
        () =>
            filterUserGroups(
                url.baseUrl,
                createFilterUserGroupsRequestFromDto(filterUserGroupsDto)
            ),
        {
            keepPreviousData: true,
            select: transformToDataTableRows,
            onError: errorResponseToDisplayHandler,
            enabled: !auth.isAdmin || (auth.isAdmin && auth.isImpersonating),
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<UserGroupResponse>>
): PaginatedTableDto<UserGroupDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((userGroup) => ({
        metadata: createBaseUserGroupDtoFromResponse(userGroup),
        showEditAction: !userGroup.isDeleted,
        showDeleteAction: !userGroup.isDeleted,
        columns: [
            { value: userGroup.name, type: DataTableColumnTypes.Text },
            { value: userGroup.email, type: DataTableColumnTypes.Text },
            { value: userGroup.userCount.toString(), type: DataTableColumnTypes.Text },
            { value: userGroup.nodeCount.toString(), type: DataTableColumnTypes.Text },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const createBaseUserGroupDtoFromResponse = (response: UserGroupResponse): UserGroupDto => ({
    ...response,
});
