import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaLink } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
    Common,
    Hierarchy,
    HierarchyTypeTreeView,
    Users,
} from "../../../core/constants/translation-namespace";
import { HierarchyMenuType } from "../../../core/hierarchyMenuType";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import { AssociateDissociateUserGroupNodeDto } from "../../../domain/dtos/user-groups/associate-dissociate-user-group-node-dto";
import {
    useAssociateUserGroupToNode,
    useGetUserGroupDetails,
} from "../../../domain/viewmodels/user-groups/associate-user-group-node-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { RadioSelectProps } from "../../molecules/DataTableAction";
import SbTabs, { SbTabItemProps } from "../../molecules/SbTabs";
import NodeHierarchyContainer from "../hierarchy/NodeHierarchyContainer";
import TextSearchContainer from "../hierarchy/TextSearchContainer";

const AssociateUserGroupNodeContainer: React.FC = () => {
    const userGroupId = Number(useParams().userGroupId);

    const [selectedNode, setSelectedNode] = useState<number>();

    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const getUserGroupDetails = useGetUserGroupDetails(userGroupId);

    const userGroupDetailsResponse = getUserGroupDetails[0];
    const userGroupDetailsResponseData = userGroupDetailsResponse.data;

    const hierarchyDetailsResponse = getUserGroupDetails[1];
    const hierarchyDetailsResponseData = hierarchyDetailsResponse.data;

    const associateUserGroupToNode = useAssociateUserGroupToNode();

    useLoader(
        areQueriesLoading(getUserGroupDetails) || isMutationLoading(associateUserGroupToNode),
        AssociateUserGroupNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.UserGroups);
    }, []);

    const selectTextSearchNode = (metadata: BaseNodeDetailsDto): void =>
        setSelectedNode(metadata.nodeId);

    const selectVisualSearchNode = (nodeId: number): void => setSelectedNode(nodeId);

    const checkSelectedNode = (metadata: BaseNodeDetailsDto): boolean => {
        const node = metadata.nodeId;

        return node === selectedNode;
    };

    const radioSelect: RadioSelectProps = {
        type: "radio",
        label: `${t("SelectNode", { keyPrefix: HierarchyTypeTreeView })}`,
    };

    const onAssignUserGroupToNode = (): void =>
        associateUserGroupToNode.mutate(
            new AssociateDissociateUserGroupNodeDto(selectedNode!, userGroupId!),
            {
                onSuccess: async () => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyAssociatedUserGroupToNode"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.UserGroups)}/${userGroupId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        const textSearchContainer = (
            <TextSearchContainer
                mode={"select"}
                selectNode={selectTextSearchNode}
                selectedNode={checkSelectedNode}
                radioSelect={radioSelect}
                hierarchyTypes={hierarchyDetailsResponseData!.sort(
                    (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                )}
            />
        );

        const nodeHierarchyContainer = (
            <NodeHierarchyContainer
                menuType={
                    HierarchyMenuType.Select |
                    HierarchyMenuType.Details |
                    HierarchyMenuType.ViewUsers
                }
                selectNode={selectVisualSearchNode}
                hierarchyTypes={hierarchyDetailsResponseData!.sort(
                    (a, b) => a.hierarchyTypeId - b.hierarchyTypeId
                )}
            />
        );

        return [
            new SbTabItemProps(t("TextSearch", { keyPrefix: Common }), textSearchContainer, true),
            new SbTabItemProps(t("VisualSearch", { keyPrefix: Common }), nodeHierarchyContainer),
        ];
    };

    return (
        <>
            <PageHeading>{t("AssignUserGroupToHierarchy", { keyPrefix: Hierarchy })}</PageHeading>
            <PageSubHeading>{t("AssignNodeHelpTextUserGroups")}</PageSubHeading>
            <LargeVerticalSpace />
            {isQuerySuccessful(userGroupDetailsResponse) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>{t("UserGroup")}</DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{userGroupDetailsResponseData?.name}</DetailsValue>
                            </Col>
                        </Row>
                    </ContentContainer>
                    <LargeVerticalSpace />
                </>
            )}

            {isQuerySuccessful(hierarchyDetailsResponse) && (
                <ContentContainer>
                    <SbTabs values={buildSbTabItemProps()}></SbTabs>
                </ContentContainer>
            )}
            <LargeVerticalSpace />
            <EndAlignedDiv>
                <Col sm={"auto"}>
                    <SbButton
                        icon={FaLink}
                        variant={"primary"}
                        type={"button"}
                        label={t("Assign", { keyPrefix: Common })}
                        onClick={onAssignUserGroupToNode}
                        disabled={!selectedNode}
                    />
                </Col>
                <Col sm={"auto"}>
                    <CancelButton onClick={() => navigate(-1)} />
                </Col>
            </EndAlignedDiv>
        </>
    );
};

export default AssociateUserGroupNodeContainer;
