import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import {
    Common,
    HierarchyTypeTreeView,
    Nodes,
    UserManagement,
    Users,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { maxContentWidthSelectStyle, NoDataStateDiv } from "../../../core/theme/global-styles";
import { nameColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    areMutationsLoading,
    areQueriesLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { AssociateDissociateUserGroupNodeDto } from "../../../domain/dtos/user-groups/associate-dissociate-user-group-node-dto";
import { UserGroupDto } from "../../../domain/dtos/users/user-group-dto";
import { hasRoleTypeInGroup, UserRoleGroup } from "../../../domain/enums/Roles";
import { useFilterUserGroupsByNode } from "../../../domain/viewmodels/hierarchy/assign-user-groups-to-node-viewmodel";
import { useFilterAssignedUsersToNode } from "../../../domain/viewmodels/hierarchy/assign-users-to-node-viewmodel";
import { useFilterUserGroups } from "../../../domain/viewmodels/hierarchy/node-details-viewmodel";
import { useAssociateUserGroupToNode } from "../../../domain/viewmodels/user-groups/associate-user-group-node-viewmodel";
import { useDissociateUserGroupNode } from "../../../domain/viewmodels/user-groups/dissociate-user-group-node-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { CancelButton, CreateIconButton, DissociateButton, SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";
import { DataTable } from "../../organisms/DataTable";

const AssignedUserGroupsToNodeContainer: React.FC<{
    selectedNodeIdToViewAssignedUserGroups: number | null;
}> = ({ selectedNodeIdToViewAssignedUserGroups }) => {
    const [userGroupSearchText, setUserGroupSearchText] = useState<string | null>(null);
    const [assigningUserGroup, setAssigningUserGroup] = useState<UserGroupDto | null>(null);
    const [removingUserGroupId, setRemovingUserGroupId] = useState<number | null>(null);
    const [showAssignUserGroupConfirmation, setShowAssignUserGroupConfirmation] = useState(false);
    const [showRemoveUserGroupConfirmation, setShowRemoveUserGroupConfirmation] = useState(false);
    const [assignedUserGroupsPaginationDto, setAssignedUserGroupsPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const auth = useAuth();
    const toast = useToast();
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const filterAssignedUsersToNode = useFilterAssignedUsersToNode(
        selectedNodeIdToViewAssignedUserGroups
    );

    const filterUserGroupsByNode = useFilterUserGroupsByNode(
        selectedNodeIdToViewAssignedUserGroups!,
        assignedUserGroupsPaginationDto
    );
    const assignedUserGroupsResponseData = filterUserGroupsByNode.data;

    const filterUserGroups = useFilterUserGroups(1, 100, userGroupSearchText);
    const associateUserGroupToNode = useAssociateUserGroupToNode();
    const dissociateUserGroupNode = useDissociateUserGroupNode();

    useLoader(
        areQueriesLoading([filterAssignedUsersToNode, filterUserGroupsByNode]) ||
            areMutationsLoading([associateUserGroupToNode, dissociateUserGroupNode]),
        AssignedUserGroupsToNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const onConfirmUserGroupAssignment = (): void =>
        associateUserGroupToNode.mutate(
            new AssociateDissociateUserGroupNodeDto(
                selectedNodeIdToViewAssignedUserGroups!,
                assigningUserGroup!.userGroupId
            ),
            {
                onSuccess: () => onUserGroupAssignmentOrRemovalSuccess(true),
                onError: errorResponseToDisplayHandler,
            }
        );

    const onConfirmUserGroupRemoval = (): void =>
        dissociateUserGroupNode.mutate(
            new AssociateDissociateUserGroupNodeDto(
                selectedNodeIdToViewAssignedUserGroups!,
                removingUserGroupId!
            ),
            {
                onSuccess: () => onUserGroupAssignmentOrRemovalSuccess(false),
                onError: errorResponseToDisplayHandler,
            }
        );

    const onUserGroupAssignmentOrRemovalSuccess = async (isAssignment: boolean): Promise<void> => {
        setAssigningUserGroup(null);
        setRemovingUserGroupId(null);
        setUserGroupSearchText(null);
        setShowAssignUserGroupConfirmation(false);
        setShowRemoveUserGroupConfirmation(false);

        if (isAssignment) {
            toast.addToast(
                createSuccessToastProps([
                    t("AssignUserGroupToNodeSuccessMessage", { keyPrefix: UserManagement }),
                ])
            );
        } else {
            toast.addToast(
                createSuccessToastProps([
                    t("SuccessfullyDissociatedUserGroupFromNode", { keyPrefix: Nodes }),
                ])
            );
        }

        queryClient.refetchQueries([
            "filterUserGroupsByNode",
            selectedNodeIdToViewAssignedUserGroups!,
            assignedUserGroupsPaginationDto,
        ]);
    };

    const buildAssignConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("UserGroupName", {
                keyPrefix: Users,
            }),
            assigningUserGroup?.name
        ),
    ];

    const buildDissociateConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("UserGroupName", {
                keyPrefix: Users,
            }),
            filterUserGroupsByNode
                .data!.rows.find((x) => x.metadata === removingUserGroupId)
                ?.columns.map((x) => x.value)[0]
        ),
    ];

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={onConfirmUserGroupRemoval} />
    );

    const buildAssignButton = (): ReactElement<typeof Button> => (
        <SbButton
            onClick={onConfirmUserGroupAssignment}
            variant={"primary"}
            type={"button"}
            label={t("Yes", { keyPrefix: Common })}
        />
    );

    const buildNoButton = (): ReactElement<typeof Button> => (
        <SbButton
            onClick={() => {
                setShowAssignUserGroupConfirmation(false);
                setShowRemoveUserGroupConfirmation(false);
            }}
            variant={"secondary"}
            type={"button"}
            label={t("No", { keyPrefix: Common })}
        />
    );

    const buildCancelButton = (): ReactElement<typeof Button> => (
        <CancelButton
            onClick={() => {
                setShowAssignUserGroupConfirmation(false);
                setShowRemoveUserGroupConfirmation(false);
            }}
        />
    );

    return (
        <>
            {selectedNodeIdToViewAssignedUserGroups != null &&
                isQuerySuccessful(filterAssignedUsersToNode) &&
                isQuerySuccessful(filterUserGroupsByNode) &&
                !showAssignUserGroupConfirmation &&
                !showRemoveUserGroupConfirmation && (
                    <>
                        {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.AssignRoles) &&
                            filterAssignedUsersToNode.data!.isNodeUserAssignable && (
                                <Row>
                                    <Col>
                                        <SbSelect
                                            name={"user groups"}
                                            placeholderText={`${t("SearchForUserGroup", {
                                                keyPrefix: Users,
                                            })}`}
                                            searchable
                                            clearable
                                            styles={maxContentWidthSelectStyle}
                                            items={filterUserGroups?.data}
                                            loading={filterUserGroups.isFetching}
                                            itemLabel={(userGroup: UserGroupDto) =>
                                                `${userGroup.name} - ${userGroup.email}`
                                            }
                                            onSearchTextChanged={(text: string) =>
                                                setUserGroupSearchText(text)
                                            }
                                            value={assigningUserGroup}
                                            onChange={(
                                                userGroupOption: SingleValue<UserGroupDto>
                                            ) => setAssigningUserGroup(userGroupOption)}
                                        />
                                    </Col>
                                    <Col sm="auto">
                                        <CreateIconButton
                                            type={"button"}
                                            label={t("AssignUserGroup", {
                                                keyPrefix: HierarchyTypeTreeView,
                                            })}
                                            onClick={() => {
                                                if (assigningUserGroup) {
                                                    setShowAssignUserGroupConfirmation(true);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}
                        {assignedUserGroupsResponseData!.rows.length ? (
                            <DataTable
                                columns={nameColumnNames}
                                rows={assignedUserGroupsResponseData!.rows}
                                deleteItem={(userGroupId: number) => {
                                    setRemovingUserGroupId(userGroupId);

                                    setShowRemoveUserGroupConfirmation(true);
                                }}
                                totalItems={assignedUserGroupsResponseData!.recordCount}
                                paginationDto={assignedUserGroupsPaginationDto}
                                setPaginationDto={setAssignedUserGroupsPaginationDto}
                            />
                        ) : (
                            <NoDataStateDiv>
                                {t("NoUserGroups", {
                                    keyPrefix: HierarchyTypeTreeView,
                                })}
                            </NoDataStateDiv>
                        )}
                    </>
                )}

            {showAssignUserGroupConfirmation && (
                <>
                    <ActionConfirmationFragment
                        panelTitle={t("AreYouSureYouWantToAssignUserGroup", {
                            keyPrefix: HierarchyTypeTreeView,
                        })}
                        rows={buildAssignConfirmationRows()}
                        primaryButton={buildAssignButton()}
                        cancelButton={buildNoButton()}
                        areQueriesSuccessful={isQuerySuccessful(filterUserGroupsByNode)}
                        isNested={true}
                    />
                </>
            )}

            {showRemoveUserGroupConfirmation && (
                <>
                    <ActionConfirmationFragment
                        panelTitle={t("AreYouSureYouWantToRemoveUserGroup", {
                            keyPrefix: HierarchyTypeTreeView,
                        })}
                        rows={buildDissociateConfirmationRows()}
                        primaryButton={buildDissociateButton()}
                        cancelButton={buildCancelButton()}
                        areQueriesSuccessful={isQuerySuccessful(filterUserGroupsByNode)}
                        isNested={true}
                    />
                </>
            )}
        </>
    );
};

export default AssignedUserGroupsToNodeContainer;
