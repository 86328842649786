import { AccordionTitles, DrawerTitles, NavbarTitles } from "./enums";

export const getPath = (item: string): string => {
    switch (item) {
        // Login
        case "Login":
            return "/login";
        case "Logout":
            return "/logout";
        // Navbar
        case NavbarTitles.Help:
            return "/";
        case NavbarTitles.Support:
            return "/";
        case "Wizard Notification History":
            return "/wizard-notification-history";
        case "Profile":
            return "/profile";
        // Drawer
        case DrawerTitles.TeamAnswerSummary:
            return "/team-answer-summary";
        case DrawerTitles.AnswerSummary:
            return "/answer-summary";
        case DrawerTitles.AnswerCapture:
            return "/answer-capture";
        case DrawerTitles.CompletedAnswers:
            return "/completed-answers";
        case DrawerTitles.TriggerAdhocs:
            return "/trigger-adhocs";
        case DrawerTitles.PendingAdhocs:
            return "/pending-adhocs";
        case DrawerTitles.Reviews:
            return "/reviews";
        case DrawerTitles.ReviewsHistory:
            return "/reviews-history";
        case DrawerTitles.Library:
            return "/library";
        case DrawerTitles.Hierarchy:
            return "/hierarchy";
        case DrawerTitles.NonWorkingDay:
            return "/non-working-day";
        case DrawerTitles.EmailQueue:
            return "/email-queue";
        case DrawerTitles.MyActionCalendar:
            return "/my-action-calendar";
        case DrawerTitles.MyActionSummary:
            return "/my-action-summary";
        case DrawerTitles.MyActionItems:
            return "/my-action-items";
        case DrawerTitles.FinalizedActionItems:
            return "/finalized-action-items";
        case DrawerTitles.ActionItemDynamicFields:
            return "/action-item-dynamic-fields";
        case DrawerTitles.ActionItemDynamicFieldTexts:
            return "/action-item-dynamic-field-texts";
        case DrawerTitles.ActionPlans:
            return "/action-plans";
        case DrawerTitles.ActionItems:
            return "/action-items";
        case DrawerTitles.NotificationTriggers:
            return "/notification-triggers";
        case DrawerTitles.Dashboard:
            return "/dashboard";
        // Accordion
        case AccordionTitles.Checklists:
            return "/checklists";
        case AccordionTitles.QuestionSets:
            return "/question-sets";
        case AccordionTitles.Questions:
            return "/questions";
        case AccordionTitles.QuestionCategories:
            return "/question-categories";
        case AccordionTitles.AnswerTypes:
            return "/answer-types";
        case AccordionTitles.AnswerTypePredefinedAnswers:
            return "/predefined-answers";
        case AccordionTitles.AnswerTypePredefinedAnswerTexts:
            return "/predefined-answer-texts";
        case AccordionTitles.ReferentialLinkTypes:
            return "/referential-link-types";
        case AccordionTitles.Import:
            return "/import";
        case AccordionTitles.VisualTree:
            return "/nodes";
        case AccordionTitles.Search:
            return "/search";
        case AccordionTitles.VisualStructure:
            return "/node-types";
        case AccordionTitles.VisualStructureValues:
            return "/node-type-values";
        case AccordionTitles.Admin:
            return "/hierarchy-types";
        case AccordionTitles.HierarchyImport:
            return "/hierarchy-import";
        case AccordionTitles.Users:
            return "/users";
        case AccordionTitles.UserGroups:
            return "/user-groups";
        case DrawerTitles.Audits:
            return "/audits-log";
        case AccordionTitles.ImageLibrary:
            return "/image-library";
        case AccordionTitles.Wizards:
            return "/wizards";
        default:
            return "/";
    }
};
