import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { Common, Tenants, Users } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { userGroupsColumnNames } from "../../../core/utilities/dataTableColumns";
import { userManagementColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import {
    defaultFilterUserGroupsDto,
    FilterUserGroupsDto,
} from "../../../domain/dtos/users/filter-user-groups-dto";
import { UserGroupDto } from "../../../domain/dtos/users/user-group-dto";
import { hasRoleTypeInGroup, UserRoleGroup } from "../../../domain/enums/Roles";
import { useFilterUserGroups } from "../../../domain/viewmodels/user-groups/view-user-groups-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { UserGroupsFilter } from "../../organisms/filters/UserGroupsFilter";

interface SearchParams {
    name: string | null;
    email: string | null;
    isDeleted: boolean | null;
}

const createSearchParams = (
    name: string | null,
    email: string | null,
    isDeleted: boolean | null
): SearchParams => ({
    name: name,
    email: email,
    isDeleted: isDeleted,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null);

const UserGroupsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterUserGroupsDto>(defaultFilterUserGroupsDto);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();

    const menu = useMenu();
    const toast = useToast();
    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const filterUserGroups = useFilterUserGroups(filterDto);
    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(isQueryLoading(filterUserGroups), UserGroupsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.UserGroups);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const sortBy = (column: string): void => {
        setFilterDto({
            ...filterDto,
            sortByColumn: userManagementColumnToProperty[column],
            sortByDescending: !filterDto.sortByDescending,
        });
    };

    const navigateToViewUserGroup = (metadata: UserGroupDto): void =>
        navigate(`${getPath(AccordionTitles.UserGroups)}/${metadata.userGroupId}`);

    const navigateToDeleteUserGroup = (metadata: UserGroupDto): void => {
        const { userGroupId, email } = metadata;

        return hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.SuspensionRoles)
            ? navigate(`${getPath(AccordionTitles.UserGroups)}/${userGroupId}/delete`)
            : undefined;
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultBasePaginationDto, ...defaultSearchParams });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const changeGroupName = (name: string): void => {
        setSearchParams({
            ...searchParams,
            name: name !== "" ? name : null,
        });
    };

    const changeGroupEmailAddress = (email: string): void => {
        setSearchParams({
            ...searchParams,
            email: email !== "" ? email : null,
        });
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("UserGroupManagement")}</PageHeading>
            <PageSubHeading>{t("UserGroupHeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            {auth.isAdmin && !auth.isImpersonating ? (
                <SbAlert text={t("TenantRequired", { keyPrefix: Tenants })} variant="info" />
            ) : (
                <>
                    <UserGroupsFilter
                        name={searchParams.name}
                        changeGroupName={changeGroupName}
                        email={searchParams.email}
                        changeGroupEmailAddress={changeGroupEmailAddress}
                        search={search}
                        resetFilter={resetFilter}
                    />
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.UserGroupRoles) && (
                            <CreateLink
                                label={t("CreateNew", { keyPrefix: Common })}
                                navigateTo={`${getPath(AccordionTitles.UserGroups)}/create`}
                            />
                        )}
                    </EndAlignedDiv>
                    <LargeVerticalSpace />

                    {isQuerySuccessful(filterUserGroups) && (
                        <DataTable
                            columns={userGroupsColumnNames}
                            rows={filterUserGroups.data!.rows}
                            keyPrefix={Users}
                            viewItem={navigateToViewUserGroup}
                            sortBy={sortBy}
                            totalItems={filterUserGroups.data!.recordCount}
                            paginationDto={filterDto}
                            setPaginationDto={setFilterDto}
                            deleteItem={
                                hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.UserGroupRoles)
                                    ? navigateToDeleteUserGroup
                                    : undefined
                            }
                        />
                    )}
                </>
            )}
        </>
    );
};

export default UserGroupsContainer;
