import { EditUserGroupDto } from "../../dtos/user-groups/user-group-dto";

export class UserGroupRequest {
    userGroupId: number;
    name: string;
    emailAddress: string;
    description: string;

    public constructor(dto: EditUserGroupDto)
    {
        this.userGroupId = dto.userGroupId;
        this.name = dto.name;
        this.emailAddress = dto.emailAddress;
        this.description = dto.description;
    }
}
